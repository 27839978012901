import { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, OverlayTrigger, Col, Button, Popover, Modal, Alert, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faRedo, faCopy, faBan, faBug, faExclamationCircle, faInfoCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import EvoDataService from "../../Dataservices/EvoDataService";
import './EvoJobStatus.css';
import { addDaysToDate, formatUTCDateAsDashString } from "../../Functions/DateTimeHelper";
import { LoadingGif } from "../PublicComponents/HelperComponents";
import { getErrorMessage } from "../../Functions/CommonHelper";
import ReactDatePicker from "../ReactDatepicker";
import Table from 'react-bootstrap/Table'

function EvoJobStatus() {
    const pipelineStages = useRef({
        'dataAvailability': 'Data Availability',
        'aggregation': 'Aggregation',
        'shardProcessing': 'Shard Processing',
        'shardIngestion': 'Shard Ingestion'
    });
    const [startDate, setStartDate] = useState(addDaysToDate(new Date(), -7));
    const [endDate, setEndDate] = useState(new Date());
    const [serviceIds, setServiceIds] = useState([]);
    const [serviceIdToNameMap, setServiceIdToNameMap] = useState({});
    const [serviceToLogMap, setServiceToLogMap] = useState({});
    const [logToScheduledJobMap, setLogToScheduledJobMap] = useState({});
    const [logToSheduledTillMap, setLogToSheduledTillMap] = useState({});
    const [logToLoadingScheduledMap, setLogToLoadingScheduledMap] = useState({});
    const [scheduledJobToServiceLogJobsMap, setScheduledJobToServiceLogJobsMap] = useState({});
    const [scheduledToExpandedMap, setScheduledToExpandedMap] = useState({});
    const [scheduledToIsRerunningMap, setScheduledToIsRerunningMap] = useState({});
    const [scheduledToIsCancellingMap, setScheduledToIsCancellingMap] = useState({});
    const [scheduledToIsRefreshingMap, setScheduledToIsRefreshingMap] = useState({});
    const [debugModalShow, setDebugModalShow] = useState(false);
    const [debugInfo, setDebugInfo] = useState({});
    const [jobStatisticsModalShow, setJobStatisticsModalShow] = useState(false);
    const [jobStatistics, setJobStatistics] = useState({});
    const [jobStatisticsAvailable, setJobStatisticsAvailable] = useState(false);
    const [error, setError] = useState('');
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        Object.keys(logToScheduledJobMap).forEach(logId => {
            let scheduledJobs = logToScheduledJobMap[logId];

            if (scheduledJobs.length !== 0) {
                let maxDate = scheduledJobs.reduce((max, job) => {
                    return max < job.dataDate ? job.dataDate : max;
                }, scheduledJobs[0].dataDate);
                console.log(maxDate);

                setLogToSheduledTillMap({ ...logToSheduledTillMap, [logId]: maxDate })
            }

        });
    }, [logToScheduledJobMap]);

    useEffect(() => {
        Object.keys(serviceToLogMap).forEach(serviceId => {
            serviceToLogMap[serviceId].forEach(log => {
                getMonitoredScheduledJobs(serviceId, log.serviceLogId);
            });
        });
    }, [serviceToLogMap]);

    useEffect(() => {
        if (!isPageLoading) return;
        if (serviceIds.length !== 0) return;
        if (Object.keys(serviceIdToNameMap).length !== 0) return;
        if (Object.keys(serviceToLogMap).length !== 0) return;
        if (Object.keys(logToLoadingScheduledMap).length !== 0) return;
        if (Object.keys(logToScheduledJobMap).length !== 0) return;
        if (Object.keys(scheduledJobToServiceLogJobsMap).length !== 0) return;
        if (Object.keys(scheduledToExpandedMap).length !== 0) return;
        if (Object.keys(scheduledToIsRerunningMap).length !== 0) return;
        if (Object.keys(scheduledToIsCancellingMap).length !== 0) return;

        EvoDataService.getServiceToLogMap()
            .then(map => {
                let rawMap = map.data.map(m => {
                    // Add this so that frontend doesn't need to change too much if the object keys returned from backend change
                    return {
                        service: {
                            serviceId: m.service.serviceId,
                            serviceName: m.service.serviceName,
                            status: m.service.status
                        },
                        serviceLogs: m.serviceLogs.map(l => {
                            return {
                                serviceLogId: l.serviceLogId,
                                serviceLogName: l.serviceLogName,
                                status: l.status
                            }
                        })
                    }
                });
                setIsPageLoading(false);

                let servIds = [];
                let logToLoading = {};
                let serviceIdName = {};
                let serviceToLog = {};
                rawMap.forEach((m) => {
                    servIds.push(m.service.serviceId);
                    serviceIdName[m.service.serviceId] = `${m.service.serviceName} (${m.service.status})`;

                    m.serviceLogs.forEach(log => {
                        let combinedKey = getServiceAndLogIdCombinedKey(m.service.serviceId, log.serviceLogId);
                        logToLoading[combinedKey] = true;

                        if (serviceToLog[m.service.serviceId] === undefined) {
                            serviceToLog[m.service.serviceId] = [];
                        }
                        serviceToLog[m.service.serviceId].push({
                            serviceLogId: log.serviceLogId,
                            serviceLogName: `${log.serviceLogName} (${log.status})`
                        });
                    });
                });

                setServiceIds(servIds);  // Add this array to maintain the order of services returned from backend, so that deprecated services are always shown at the bottom
                setServiceIdToNameMap(serviceIdName);
                setServiceToLogMap(serviceToLog);
                setLogToLoadingScheduledMap(logToLoading);
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setIsPageLoading(false);
            });
    }, [isPageLoading, serviceIds, serviceIdToNameMap, serviceToLogMap, logToLoadingScheduledMap, logToScheduledJobMap, scheduledJobToServiceLogJobsMap, scheduledToExpandedMap, scheduledToIsRerunningMap, scheduledToIsCancellingMap]);

    const getServiceAndLogIdCombinedKey = (serviceId, logId) => `${serviceId}_${logId}`;


    const getMonitoredScheduledJobs = (serviceId, serviceLogId) => {
        let combinedKey = getServiceAndLogIdCombinedKey(serviceId, serviceLogId);
        logToScheduledJobMap[combinedKey] = [];

        logToLoadingScheduledMap[combinedKey] = true;
        setLogToLoadingScheduledMap({ ...logToLoadingScheduledMap });

        return EvoDataService.getMonitoredScheduledJobs(formatUTCDateAsDashString(startDate), formatUTCDateAsDashString(endDate), serviceId, serviceLogId)
            .then(res => {
                res.data.forEach(scheduledJob => {
                    logToScheduledJobMap[combinedKey].push({
                        scheduledJobId: scheduledJob.scheduledJobId,
                        dataDate: scheduledJob.dataDate,
                        processAfterDate: scheduledJob.processAfterDate,
                        processByDate: scheduledJob.processByDate,
                        scheduleIteration: scheduledJob.scheduleIteration,
                        status: scheduledJob.status
                    });

                    scheduledToExpandedMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsCancellingMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsRerunningMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsRefreshingMap[scheduledJob.scheduledJobId] = false;

                    setLogToScheduledJobMap({ ...logToScheduledJobMap });

                    getServiceLogJobRunsByScheduledJobId(scheduledJob.scheduledJobId, false);

                    setScheduledToExpandedMap({ ...scheduledToExpandedMap });
                    setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap });
                    setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap });
                    setScheduledToIsRefreshingMap({ ...scheduledToIsRefreshingMap })
                });
                logToLoadingScheduledMap[combinedKey] = false;
                setLogToLoadingScheduledMap({ ...logToLoadingScheduledMap });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    }

    const refreshPage = () => {
        setError('');
        setIsPageLoading(true);
        setServiceIds([]);
        setServiceIdToNameMap({});
        setServiceToLogMap({});
        setLogToScheduledJobMap({});
        setLogToSheduledTillMap({});
        setLogToLoadingScheduledMap({});
        setScheduledToExpandedMap({});
        setScheduledToIsRerunningMap({});
        setScheduledToIsCancellingMap({});
        setScheduledToIsRefreshingMap({});
        setScheduledJobToServiceLogJobsMap({});
    };

    const refreshLog = (serviceId, serviceLogId) => {
        getMonitoredScheduledJobs(serviceId, serviceLogId);
    }

    const refreshJob = (serviceId, serviceLogId, scheduledJobId) => {
        setScheduledToIsRefreshingMap({ ...scheduledToIsRefreshingMap, [scheduledJobId]: true });

        EvoDataService.getMonitoredScheduledJobs(formatUTCDateAsDashString(startDate), formatUTCDateAsDashString(endDate), serviceId, serviceLogId)
            .then(res => {
                let combinedKey = getServiceAndLogIdCombinedKey(serviceId, serviceLogId);
                let jobIndex = logToScheduledJobMap[combinedKey].findIndex(x => x.scheduledJobId === scheduledJobId)
                logToScheduledJobMap[combinedKey][jobIndex].status = res.data[jobIndex].status;
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });

        getServiceLogJobRunsByScheduledJobId(scheduledJobId, true)
            .finally(() => {
                setScheduledToIsRefreshingMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            })

    }

    const getServiceLogJobRunsByScheduledJobId = (scheduledJobId, expandByDefault) => {
        return EvoDataService.getServiceLogJobRunsByScheduledJobId(scheduledJobId)
            .then(res => {
                scheduledJobToServiceLogJobsMap[scheduledJobId] = res.data;
                scheduledToExpandedMap[scheduledJobId] = expandByDefault;
                setScheduledJobToServiceLogJobsMap({ ...scheduledJobToServiceLogJobsMap });
                setScheduledToExpandedMap({ ...scheduledToExpandedMap });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const onExpandScheduledJobs = (scheduledJobId) => {
        scheduledToExpandedMap[scheduledJobId] = !scheduledToExpandedMap[scheduledJobId];
        setScheduledToExpandedMap({ ...scheduledToExpandedMap });
    };

    const onExpandSerivceLogJobs = (scheduledJobId, serviceLogJobRunId) => {
        let index = scheduledJobToServiceLogJobsMap[scheduledJobId].findIndex(x => x.serviceLogJobRunId === serviceLogJobRunId)
        scheduledJobToServiceLogJobsMap[scheduledJobId][index].isExpanded = !scheduledJobToServiceLogJobsMap[scheduledJobId][index].isExpanded;
        setScheduledJobToServiceLogJobsMap({ ...scheduledJobToServiceLogJobsMap });
    };

    const toggleDebugModal = () => {
        setDebugModalShow(!debugModalShow);
    };

    const toggleJobStatisticsModal = () => {
        setJobStatisticsModalShow(!jobStatisticsModalShow);
    };

    const getLogProperty = (serviceLogJobRunId) => {
        setError('');
        EvoDataService.getLogPropertyForMonitoring(serviceLogJobRunId)
            .then(res => {
                setDebugInfo(Object.keys(res.data).reduce((pre, curr) => {
                    pre[curr] = JSON.stringify(res.data[curr], null, 4);
                    return pre;
                }, {}));
                toggleDebugModal();
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const debugPipelineStage = (stageRunId, stage) => {
        let debugFunc;
        switch (stage) {
            case 'dataAvailability':
                debugFunc = EvoDataService.getDataAvailabilityDiagnostic;
                break;
            case 'aggregation':
                debugFunc = EvoDataService.getAggregationDiagnostic;
                break;
            case 'shardProcessing':
                debugFunc = EvoDataService.getShardProcessingDiagnostic;
                break;
            case 'shardIngestion':
                debugFunc = EvoDataService.getShardIngestionDiagnostic;
                break;
            default:
                break;
        }

        setError('');
        debugFunc(stageRunId)
            .then(res => {
                setDebugInfo(res.data);
                toggleDebugModal();
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const getJobStatistics = (jobGuid) => {
        setError('');

        EvoDataService.getJobStatistics(jobGuid).then(
            res => {
                setJobStatistics(res.data);
                toggleJobStatisticsModal();
                setJobStatisticsAvailable(true);
            }
        ).catch(error => {
            setJobStatistics({ "Error Message:": "There are no job statistics available for this job. Error Message: " + getErrorMessage(error, true) })
            toggleJobStatisticsModal();
            setJobStatisticsAvailable(false);
        });
    };

    const onStartDateChange = (date) => {
        setStartDate(date);
    };

    const onEndDateChange = (date) => {
        setEndDate(date);
    };

    const onCopy = (content) => {
        navigator.clipboard.writeText(content);
    };

    const getColor = (status) => {
        switch (status) {
            case 'Success':
            case 'Completed':
                return '#28a745';
            case 'Failure':
            case 'NonRetriableError':
                return '#dc3545';
            case 'Running':
            case 'InProgress':
            case 'Processing':
                return '#2684ff';
            case 'RetriableError':
            case 'Cancelled':
                return '#ffc107';
            default:
                return 'black';
        }
    };

    const onRerunJob = (scheduledJobId, serviceId, serviceLogId) => {
        let combinedKey = getServiceAndLogIdCombinedKey(serviceId, serviceLogId);
        let jobIndex = logToScheduledJobMap[combinedKey].findIndex(x => x.scheduledJobId === scheduledJobId)
        setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: true });
        EvoDataService.rerunJob(scheduledJobId)
            .then(() => {
                logToScheduledJobMap[combinedKey][jobIndex].status = 'Processing';
                setLogToScheduledJobMap({ ...logToScheduledJobMap });
                getServiceLogJobRunsByScheduledJobId(scheduledJobId, true);
                setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            });
    };

    const onCancelJob = (scheduledJobId, serviceId, serviceLogId) => {
        let combinedKey = getServiceAndLogIdCombinedKey(serviceId, serviceLogId);
        let jobIndex = logToScheduledJobMap[combinedKey].findIndex(x => x.scheduledJobId === scheduledJobId)
        setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: true });
        EvoDataService.cancelJob(scheduledJobId)
            .then(() => {
                logToScheduledJobMap[combinedKey][jobIndex].status = 'Completed';
                setLogToScheduledJobMap({ ...logToScheduledJobMap });
                getServiceLogJobRunsByScheduledJobId(scheduledJobId, true);
                setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: false });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: false });
            })
    };

    const calculateTimeDifference = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return 'Job Duration Unavailable';
        }
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        if (startDate > endDate) {
            return 'Start time is later than end time';
        }

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDate - startDate;

        // Ensure the difference is positive
        const absoluteTimeDifferenceMs = Math.abs(timeDifferenceMs);

        // Convert milliseconds to seconds, minutes, hours, and days
        const seconds = Math.floor(absoluteTimeDifferenceMs / 1000) % 60;
        const minutes = Math.floor(absoluteTimeDifferenceMs / (1000 * 60)) % 60;
        const hours = Math.floor(absoluteTimeDifferenceMs / (1000 * 60 * 60)) % 24;
        const days = Math.floor(absoluteTimeDifferenceMs / (1000 * 60 * 60 * 24));

        // Construct the human-readable string
        let result = '';

        if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
        if (hours > 0 || days > 0) result += `${hours} hour${hours > 1 ? 's' : ''} `;
        if (minutes > 0 || hours > 0 || days > 0) result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
        result += `${seconds} second${seconds > 1 ? 's' : ''}`;

        return result.trim();
    }

    const calculateConsumedAUs = (startTime, endTime, au) => {
        if (!startTime || !endTime || !au) {
            return '';
        }
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        if (startDate > endDate) {
            return 'Start time is later than end time';
        }

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDate - startDate;

        // Ensure the difference is positive
        const absoluteTimeDifferenceMs = Math.abs(timeDifferenceMs);
        var value = (absoluteTimeDifferenceMs / 3600000) * au;

        //Only get three decimal places
        return Math.trunc(1000 * value) / 1000;
    }

    const renderPipelineStage = (stageInfo, stage) => {
        return (
            <Row className="pipeline-stage-row" key={stageInfo.stageRunId}>
                <Col className="property-cell"></Col>
                <Col className="property-cell">
                    <span>Stage Name</span>
                    {pipelineStages.current[stage]}
                </Col>
                <Col className="property-cell">
                    <span>Status</span>
                    <div style={{ color: getColor(stageInfo.status) }}>{stageInfo.status}</div>
                </Col>
                <Col className="property-cell">
                    <span>Stage Start On</span>
                    {stageInfo.submittedOn}
                </Col>
                <Col className="property-cell">
                    <span>Stage End On</span>
                    {stageInfo.endOn}
                </Col>
                <Col className="property-cell">
                    <span>Error Count</span>
                    {stageInfo.errorCount}
                </Col>
                <Col className="property-cell">
                    {stageInfo.errorMessage && <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={
                            <Popover>
                                <Popover.Title as="div">
                                    <Button variant="outline-secondary" onClick={onCopy.bind(this, stageInfo.errorMessage)}>
                                        <FontAwesomeIcon icon={faCopy} /> &ensp;Copy to clipboard
                                    </Button>
                                </Popover.Title>
                                <Popover.Content style={{ maxHeight: 500, overflowY: 'auto' }}>
                                    {stageInfo.errorMessage}
                                </Popover.Content>
                            </Popover>
                        }>
                        <Button variant="outline-primary"><FontAwesomeIcon icon={faExclamationCircle} />&ensp;Show Error Message</Button>
                    </OverlayTrigger>}
                </Col>
                <Col className="property-cell, btn-cell">
                    <Button variant="outline-primary" onClick={debugPipelineStage.bind(this, stageInfo.stageRunId, stage)}>
                        <FontAwesomeIcon icon={faBug} />&ensp;
                        Debug
                    </Button>
                    {stageInfo.jobGuid &&
                        <Button variant="outline-primary" onClick={getJobStatistics.bind(this, stageInfo.jobGuid)}>
                            <FontAwesomeIcon icon={faInfoCircle} />&ensp;
                            Analysis
                        </Button>}
                </Col>
            </Row>
        );
    };

    return (
        <Container fluid className="evo-job-status">
            {error && <Row>
                <Alert variant="danger" className="error">
                    {error}
                </Alert>
            </Row>}
            <Row className="date-range-refresh">
                <div className="date-label">From</div>
                <ReactDatePicker
                    className="date-picker"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={onStartDateChange} />
                <div className="date-label">To</div>
                <ReactDatePicker
                    className="date-picker"
                    placeholder="End Date"
                    value={endDate}
                    onChange={onEndDateChange} />
                <Button variant="outline-primary" onClick={refreshPage} disabled={isPageLoading} className="refresh-btn"><FontAwesomeIcon icon={faRedo} />&ensp;Refresh</Button>
            </Row>
            {isPageLoading ? <Row>
                <Col><LoadingGif /></Col>
            </Row>
                : serviceIds.map((serviceId) =>
                    <Fragment key={serviceId}>
                        <Row className="service-name">
                            {serviceIdToNameMap[serviceId]}
                        </Row>

                        {serviceToLogMap[serviceId]?.map(serviceLog =>
                            <Fragment key={serviceLog.serviceLogId}>
                                <Row>
                                    <Col className="property-cell">
                                        <span>Log Name</span>
                                        {serviceLog.serviceLogName}
                                    </Col>
                                    <Col className="property-cell">
                                        <span>Scheduled Till</span>
                                        {logToSheduledTillMap[getServiceAndLogIdCombinedKey(serviceId, serviceLog.serviceLogId)] || 'No scheduled jobs'}
                                    </Col>
                                    {/* Add extra Cols here to make the whole page vertically justified */}
                                    <Col className="property-cell"></Col>
                                    <Col className="property-cell"></Col>
                                    <Col className="property-cell"></Col>
                                    <Col className="property-cell"></Col>
                                    <Col className="property-cell"></Col>
                                    <Col className="property-cell">
                                        <Button variant="outline-primary" style={{ width: 100 }}
                                            onClick={() => refreshLog(serviceId, serviceLog.serviceLogId)}><FontAwesomeIcon icon={faRedo} />&ensp;Refresh
                                        </Button>
                                    </Col>
                                </Row>
                                {logToLoadingScheduledMap[getServiceAndLogIdCombinedKey(serviceId, serviceLog.serviceLogId)] ? <Row className="loading-scheduled-jobs">
                                    <Spinner animation="border" role="status" variant="primary" />&emsp;Loading scheduled jobs...
                                </Row>
                                    : logToScheduledJobMap[getServiceAndLogIdCombinedKey(serviceId, serviceLog.serviceLogId)] && logToScheduledJobMap[getServiceAndLogIdCombinedKey(serviceId, serviceLog.serviceLogId)].length > 0 &&
                                    logToScheduledJobMap[getServiceAndLogIdCombinedKey(serviceId, serviceLog.serviceLogId)].map((scheduledJob) =>
                                        <Fragment key={scheduledJob.scheduledJobId}>
                                            <Row className="scheduled-job-row">
                                                <Col className="property-cell-with-expand">
                                                    <Button variant="outline-primary" onClick={onExpandScheduledJobs.bind(this, scheduledJob.scheduledJobId)}>
                                                        {scheduledToExpandedMap[scheduledJob.scheduledJobId] ? <FontAwesomeIcon icon={faChevronCircleUp} /> : <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                    </Button>
                                                    <div>
                                                        <span>ScheduledJob Id</span>
                                                        {scheduledJob.scheduledJobId}
                                                    </div>
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Data Date</span>
                                                    {scheduledJob.dataDate}
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Iteration</span>
                                                    {scheduledJob.scheduleIteration}
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Status</span>
                                                    <div style={{ color: getColor(scheduledJob.status === 'Completed' ? scheduledJobToServiceLogJobsMap[scheduledJob.scheduledJobId]?.[0]?.jobStatus : scheduledJob.status) }}>
                                                        {scheduledJob.status === 'Completed'
                                                            && (!scheduledJobToServiceLogJobsMap[scheduledJob.scheduledJobId]
                                                                || !scheduledJobToServiceLogJobsMap[scheduledJob.scheduledJobId][0])
                                                            ? <Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" />
                                                            : <Fragment>
                                                                {scheduledJob.status === 'Completed'
                                                                    ? scheduledJob.status + ' - ' + scheduledJobToServiceLogJobsMap[scheduledJob.scheduledJobId]?.[0]?.jobStatus
                                                                    : scheduledJob.status}
                                                            </Fragment>}
                                                    </div>
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Process After</span>
                                                    {scheduledJob.processAfterDate}
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Process By</span>
                                                    {scheduledJob.processByDate}
                                                </Col>
                                                <Col className="btn-cell">
                                                    <Button variant="outline-primary" style={{ width: 100 }}
                                                        disabled={(scheduledJob.status !== 'Completed' && scheduledJob.status !== 'Scheduled') || scheduledToIsRerunningMap[scheduledJob.scheduledJobId]}
                                                        onClick={onRerunJob.bind(this, scheduledJob.scheduledJobId, serviceId, serviceLog.serviceLogId)}>
                                                        <FontAwesomeIcon icon={faPlayCircle} />&ensp;{scheduledJob.status === 'Completed' ? 'Rerun' : 'Trigger'}
                                                        {scheduledToIsRerunningMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                                    </Button>
                                                    <Button variant="outline-primary" style={{ width: 100 }}
                                                        disabled={scheduledJob.status !== 'Processing' || scheduledToIsCancellingMap[scheduledJob.scheduledJobId]}
                                                        onClick={onCancelJob.bind(this, scheduledJob.scheduledJobId, serviceId, serviceLog.serviceLogId)}>
                                                        <FontAwesomeIcon icon={faBan} />&ensp;Cancel
                                                        {scheduledToIsCancellingMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                                    </Button>
                                                    <Button variant="outline-primary" style={{ width: 100 }}
                                                        disabled={scheduledToIsRefreshingMap[scheduledJob.scheduledJobId]}
                                                        onClick={() => refreshJob(serviceId, serviceLog.serviceLogId, scheduledJob.scheduledJobId)}>
                                                        <FontAwesomeIcon icon={faRedo} />&ensp;Refresh
                                                        {scheduledToIsRefreshingMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                                    </Button>
                                                </Col>
                                                <Col className="property-cell"></Col>
                                            </Row>

                                            {scheduledToExpandedMap[scheduledJob.scheduledJobId] && scheduledJobToServiceLogJobsMap[scheduledJob.scheduledJobId]?.map((serviceLogJob) =>
                                                <Fragment key={serviceLogJob.serviceLogJobRunId}>
                                                    <Row className="servicelog-job-row">
                                                        <Col className="property-cell-with-expand">
                                                            <Button variant="outline-primary" onClick={onExpandSerivceLogJobs.bind(this, scheduledJob.scheduledJobId, serviceLogJob.serviceLogJobRunId)}>
                                                                {serviceLogJob.isExpanded ? <FontAwesomeIcon icon={faChevronCircleUp} /> : <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                            </Button>
                                                            <div>
                                                                <span>ServiceLogJobRun Id</span>
                                                                {serviceLogJob.serviceLogJobRunId}
                                                            </div>
                                                        </Col>
                                                        <Col className="property-cell">
                                                            <span>Job Stage</span>
                                                            {serviceLogJob.jobStage}
                                                        </Col>
                                                        <Col className="property-cell">
                                                            <span>Status</span>
                                                            <div style={{ color: getColor(serviceLogJob.jobStatus) }}>{serviceLogJob.jobStatus}</div>
                                                        </Col>
                                                        <Col className="property-cell">
                                                            <span>Created On</span>
                                                            {serviceLogJob.createdOn}
                                                        </Col>
                                                        <Col className="property-cell">
                                                            <Button variant="outline-primary" onClick={getLogProperty.bind(this, serviceLogJob.serviceLogJobRunId)}>
                                                                <FontAwesomeIcon icon={faInfoCircle} />&ensp;
                                                                Get log property
                                                            </Button>
                                                        </Col>
                                                        <Col className="property-cell"></Col>
                                                        <Col className="property-cell"></Col>
                                                        <Col className="property-cell"></Col>
                                                    </Row>

                                                    {serviceLogJob.isExpanded && <Fragment>
                                                        {serviceLogJob.dataAvailibity && renderPipelineStage(serviceLogJob.dataAvailibity, 'dataAvailability')}
                                                        {serviceLogJob.aggregation && renderPipelineStage(serviceLogJob.aggregation, 'aggregation')}
                                                        {serviceLogJob.shardProcessing && <Fragment>
                                                            {serviceLogJob.shardProcessing.map((shardProcessing) => renderPipelineStage(shardProcessing, 'shardProcessing'))}
                                                        </Fragment>}
                                                        {serviceLogJob.shardIngestion && <Fragment>
                                                            {serviceLogJob.shardIngestion.map((shardIngestion) => renderPipelineStage(shardIngestion, 'shardIngestion'))}
                                                        </Fragment>}
                                                    </Fragment>}
                                                </Fragment>
                                            )}
                                        </Fragment>)}
                            </Fragment>
                        )}
                    </Fragment>
                )}

            {debugModalShow && <Modal show={debugModalShow} onHide={toggleDebugModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Debug information</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Object.keys(debugInfo).map(key => <div className="evo-jobs-debug-info-block" key={key}>
                        <div className="title">
                            {key}
                            <Button variant="outline-secondary" onClick={onCopy.bind(this, debugInfo[key])}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </div>
                        {debugInfo[key]}
                    </div>)}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleDebugModal}>Close</Button>
                </Modal.Footer>
            </Modal>}

            {jobStatisticsModalShow && <Modal show={jobStatisticsModalShow} onHide={toggleJobStatisticsModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Job Statistics</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {jobStatistics && jobStatisticsAvailable &&
                        <div className="evo-jobs-stats-info-block">
                            <Table striped bordered hover class="job-details-table" style={{ width: "60%%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Job GUID</td>
                                        <td>{jobStatistics.jobDetails?.jobGuid}</td>
                                    </tr>
                                    <tr>
                                        <td>Job Name</td>
                                        <td>{jobStatistics.jobDetails?.jobName}</td>
                                    </tr>
                                    <tr>
                                        <td>Submitter</td>
                                        <td>{jobStatistics.jobDetails?.submitter}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Job Details:</h3>
                            <Table striped bordered hover class="job-details-table" style={{ width: "50%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Result</td>
                                        <td>{jobStatistics.jobDetails?.result}</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>{jobStatistics.jobDetails?.state}</td>
                                    </tr>
                                    <tr>
                                        <td>Priority</td>
                                        <td>{jobStatistics.jobDetails?.priority === -1 ? "" : jobStatistics.jobDetails?.priority}</td>
                                    </tr>
                                    <tr>
                                        <td>AUs</td>
                                        <td>{jobStatistics.degreeOfParallelism === -1 ? "" : jobStatistics.degreeOfParallelism}</td>
                                    </tr>
                                    <tr>
                                        <td>Consumed AUs</td>
                                        <td>{calculateConsumedAUs(jobStatistics.startTime, jobStatistics.endTime, jobStatistics.degreeOfParallelism)}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Job Size:</h3>
                            <Table striped bordered hover class="job-details-table" style={{ width: "75%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><strong>Total Row Count</strong></td>
                                        <td><strong>Total Size (Bytes) </strong></td>
                                    </tr>
                                    <tr>
                                        <td>Input</td>
                                        <td>{jobStatistics.totalInputRowCount === -1 ? "" : jobStatistics.totalInputRowCount}</td>
                                        <td>{jobStatistics.totalInputSize === -1 ? "" : jobStatistics.totalInputSize}</td>
                                    </tr>
                                    <tr>
                                        <td>Output</td>
                                        <td>{jobStatistics.totalOutputRowCount === -1 ? "" : jobStatistics.totalOutputRowCount}</td>
                                        <td>{jobStatistics.totalOutputSize === -1 ? "" : jobStatistics.totalOutputSize}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Timing:</h3>
                            <p><strong>Submit Time:</strong> {jobStatistics.submitTime}</p>
                            <p><strong>Job Duration:</strong> {calculateTimeDifference(jobStatistics.startTime, jobStatistics.endTime)}</p>
                            <div class="indented">
                                <p><strong>Start Time:</strong> {jobStatistics.startTime}</p>
                                <p><strong>End Time:</strong> {jobStatistics.endTime}</p>
                            </div>

                            <hr />

                            <h3>Job Stages:</h3>
                            <p><strong>Number of Stages:</strong> {jobStatistics.numberofStages}</p>

                            <Table striped bordered hover class="job-details-table" style={{ width: "100%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Stage Name</td>
                                        <td>Data Read (bytes)</td>
                                        <td>Data Written (bytes)</td>
                                        <td>Running Count:</td>
                                        <td>Scheduled Count:</td>
                                        <td>Failed Count:</td>
                                        <td>Succeeded Count:</td>
                                    </tr>

                                    {jobStatistics.jobStages?.map((stage, index) => (
                                        <tr key={index}>
                                            <td>{stage.stageName}</td>
                                            <td>{stage.dataRead === -1 ? "" : stage.dataRead}</td>
                                            <td>{stage.dataWritten === -1 ? "" : stage.dataWritten}</td>
                                            <td>{stage.runningCount === -1 ? "" : stage.runningCount}</td>
                                            <td>{stage.scheduledCount === -1 ? "" : stage.scheduledCount}</td>
                                            <td>{stage.failedCount === -1 ? "" : stage.failedCount}</td>
                                            <td>{stage.succeededCount === -1 ? "" : stage.succeededCount}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    }
                    {!jobStatisticsAvailable &&
                        <div>
                            {jobStatistics["Error Message:"]}
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleJobStatisticsModal}>Close</Button>
                </Modal.Footer>
            </Modal>}
        </Container>
    )
};

export default EvoJobStatus;