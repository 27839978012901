import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import DashboardDatepicker from './DashboardDatepicker'
import DashboardAddEditBoardModal from './DashboardAddEditBoardModal'
import { Route, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPen, faTimes, faFileCsv, faCopy } from '@fortawesome/free-solid-svg-icons'
import DashboardChartsContainer from './DashboardChartsContainer'
import './Dashboard.css'
import DashboardService from '../../Dataservices/DashboardService'
import IcMDataService from '../../Dataservices/IcMDataService'
import AlertingService from '../../Dataservices/AlertingService'
import { getCsvData, downloadCsv } from '../../Functions/CSVDownloader'
import { getIdFromDashboardUrl } from '../../Functions/UrlHelper'
import { getPageTitle } from '../../Functions/PageTitleHelper'
import { handleClick } from '../../Dataservices/EventTrackingService'
import { addDaysToUTCDate } from '../../Functions/DateTimeHelper'
import { DashboardLoadingOverlay, NotPermittedWarning } from '../PublicComponents/HelperComponents';
import { checkListContainsCurrentUser } from '../../Dataservices/AuthUtils'
import titles from '../../Store/titles.js';
import config from '../../config';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { components } from 'react-select';

library.add(faCog, faPen, faTimes, faFileCsv, faCopy);

class Dashboards extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isListLoading: true,
            isInfoLoading: false,
            dashboardList: [],
            dashboardIdMap: {},
            currentDashboardId: null,
            currentDashboardInfo: {},
            currentDashboardCharts: [],
            dailyStartDate: addDaysToUTCDate(new Date(), -30),
            dailyEndDate: addDaysToUTCDate(new Date(), 0),
            hourlyStartDate: addDaysToUTCDate(new Date(), -2),
            hourlyEndDate: addDaysToUTCDate(new Date(), 0),
            showAddBoardModal: false,
            showEditBoardModal: false,
            showCloneBoardModal: false,
            showAddChartModal: false,
            showDeleteBoardModal: false,
            error: null,
            noAccessPrompt: '',
            isOwner: false,
            originator: null
        }

        this.data = {
            csvData: {},
            cosmicServicesMapping: {},
            icmServices: null,
        }

    }

    componentDidMount() {
        document.title = getPageTitle(titles.DASHBOARD_UNSELECTED, null);
        const idFromUrl = getIdFromDashboardUrl(this.props.location ? this.props.location.pathname : '')
        if (idFromUrl) {
            this.switchDashboard(idFromUrl)
            this.setState({
                isListLoading: false
            })
        }

        const startTime = new Date().getTime()
        AlertingService.getOriginator("Dashboard").then(res => {
            this.setState({ originator: res.data })
        })

        DashboardService.getDashboardList().then(res => {
            const dashboards = res.data.map(d => {
                return {
                    label: d.name,
                    value: d.id,
                    isFavorite: d.isFavorite,
                    isOwner: d.isOwner,
                    isMember: d.isMember
                }
            });

            const idNameMap = res.data.reduce((map, dashboard) => {
                map[dashboard.id] = dashboard.name
                return map
            }, {})

            this.setState({
                dashboardList: dashboards,
                dashboardIdMap: idNameMap,
                isListLoading: false
            })

            if (idFromUrl) {
                document.title = getPageTitle(titles.DASHBOARD_SELECTED, idNameMap[idFromUrl]);
            }

            const endTime = new Date().getTime();
            if (config.enableEventTracking) {
                window.localStorage.setItem("loadTime", endTime - startTime);
            }
        }).catch(err => {
            console.error(err)
            this.setError(err.message)
            this.setState({ isListLoading: false })
        })

    }

    switchDashboard(dashboardId, dashboardInfo) {
        const { dashboardIdMap } = this.state
        if (dashboardIdMap[dashboardId]) {
            document.title = getPageTitle(titles.DASHBOARD_SELECTED, dashboardIdMap[dashboardId]);
        }
        this.props.history.push('/Dashboard/' + dashboardId)
        this.setState({
            currentDashboardId: dashboardId
        })

        const update = info => {
            const { dailyEndDate } = this.state
            const { loginUser } = this.props
            const newStartDate = addDaysToUTCDate(dailyEndDate, -info.lookbackTime)
            this.setState({
                currentDashboardInfo: {
                    id: info.id,
                    name: info.name,
                    owners: info.owners,
                    members: info.members,
                    email: info.email,
                    lookbackTime: info.lookbackTime,
                    isPrivate: info.isPrivate,
                    icmServiceInfo: info.icmServiceInfo,
                    icmTeamInfo: info.icmTeamInfo,
                    icmConfigId: info.icmConfigId
                },
                currentDashboardCharts: info.charts,
                dailyStartDate: newStartDate,
                isInfoLoading: false,
            })
            /**
             * Permission check
             * If the code flow reaches here, it's one of the following situations:
             *      1. The login user is admin. In this case, no matter the MSR is public or private the login user is an owner.
             *      2. The login user is not admin. In this case, need to check if the login user is an owner.
             *  */
            if (loginUser.isAdmin) {
                this.setState({
                    isOwner: true,
                    noAccessPrompt: ''
                })
            } else {
                const ownerCallBack = (isOwner) => {
                    this.setState({
                        isOwner: isOwner,
                        noAccessPrompt: ''
                    })
                }
                checkListContainsCurrentUser(info.owners, loginUser.userName, ownerCallBack)
            }
        }

        if (dashboardInfo) {
            update(dashboardInfo)
        } else {
            this.setState({
                currentDashboardCharts: [],
                currentDashboardInfo: {},
                isInfoLoading: true,
                error: '',
                noAccessPrompt: ''
            })
            DashboardService.getDashboardInfo(dashboardId).then(dashboardResult => {
                let currInfo = dashboardResult.data
                if (currInfo.icmConfigId == null) {
                    currInfo.icmServiceInfo = null
                    currInfo.icmTeamInfo = null
                    currInfo.icmConfigId = null
                    update(currInfo)
                } else {
                    IcMDataService.getIcmTeam(currInfo.icmConfigId).then(icmResult => {
                        let serviceInfo = []
                        serviceInfo.value = icmResult.data.ServiceConnectorId
                        serviceInfo.label = icmResult.data.ServiceName
                        serviceInfo.serviceId = icmResult.data.ServiceId

                        let teamInfo = []
                        teamInfo.value = icmResult.data.RoutingId
                        teamInfo.label = icmResult.data.TeamName

                        currInfo.icmServiceInfo = serviceInfo
                        currInfo.icmTeamInfo = teamInfo
                        currInfo.icmConfigId = icmResult.data.IcmConfigId
                        update(currInfo)
                    });
                }
            }).catch(err => {
                if (err.response && err.response.status === 403) {
                    // If the code flow reaches here, the dashboard is private and the login user is not a member, an owner or admin. Access denied. 
                    this.setState({
                        noAccessPrompt: err.response.data
                    })
                } else {
                    console.error(err)
                    this.setError(err.message)
                }
                this.setState({ isInfoLoading: false })
            })
        }

    }

    selectDashboard = item => {
        const dashboardId = item.value;
        if (dashboardId) {
            this.switchDashboard(dashboardId)
            handleClick(item.label, "menu", "dashboard")
        }
    }

    selectDashboardDate = (datepicker) => {
        this.setState({
            dailyStartDate: datepicker.dailyStartDate,
            dailyEndDate: datepicker.dailyEndDate,
            hourlyStartDate: datepicker.hourlyStartDate,
            hourlyEndDate: datepicker.hourlyEndDate
        });
    }

    refreshChartUrl = (id, url) => {
        let newDashboard = this.state.currentDashboardCharts.map(chart => {
            if (chart.id === id) {
                chart.url = url
            }
            return chart
        })
        this.setState({ currentDashboardCharts: newDashboard })
    }

    toggleAddBoardModal = () => {
        this.setState(prevState => {
            return {
                showAddBoardModal: !prevState.showAddBoardModal
            }
        });
        handleClick("+dashboard", "menu", "dashboard")
    }

    toggleEditBoardModal = () => {
        this.setState(prevState => {
            return {
                showEditBoardModal: !prevState.showEditBoardModal
            }
        })
        handleClick("edit", "menu/dashboardTool", "dashboard");
    }

    toggleAddChartModal = () => {
        this.setState(prevState => {
            return {
                showAddChartModal: !prevState.showAddChartModal
            }
        })
        handleClick("+chart", "menu", "dashboard")
    }

    toggleDeleteBoardModal = () => {
        this.setState(prevState => {
            return {
                showDeleteBoardModal: !prevState.showDeleteBoardModal
            }
        })
        handleClick("delete", "menu/dashboardTool", "dashboard");
    }

    toggleCloneBoardModal = () => {
        this.setState(prevState => {
            return {
                showCloneBoardModal: !prevState.showCloneBoardModal
            }
        })
        handleClick("clone", "menu/gearIcon", "dashboard");
    }

    addChart = chart => {
        const { currentDashboardCharts } = this.state
        const newList = [...currentDashboardCharts, chart]
        this.setState({ currentDashboardCharts: newList })
    }

    editChart = editedChart => {
        const { currentDashboardCharts } = this.state

        const newList = currentDashboardCharts.map(chart => {
            if (chart.id === editedChart.id) {
                return editedChart
            } else {
                return chart
            }
        })

        this.setState({ currentDashboardCharts: newList })
    }

    deleteChart = chartId => {
        const { currentDashboardCharts } = this.state
        let toDelete = null
        const newList = currentDashboardCharts.filter(chart => {
            if (chart.id === chartId) {
                toDelete = chart
            }
            return chart.id !== chartId
        })

        newList.forEach((chart, index) => {
            chart.order = index
        })

        this.setState({ currentDashboardCharts: newList })

        toDelete.dataSuiteId = null
        DashboardService.deleteChart(toDelete).then().catch(err => {
            console.error(err);
            this.setError(err.message);
        })
    }

    moveChart = (oldIndex, newIndex) => {
        const { currentDashboardCharts, currentDashboardInfo } = this.state
        const len = currentDashboardCharts.length

        const toMove = currentDashboardCharts[oldIndex]
        const newList = currentDashboardCharts.slice()
        newList.splice(oldIndex, 1)
        newList.splice(Math.min(newIndex, len), 0, toMove)
        newList.forEach((chart, index) => {
            chart.order = index
        })

        const payload = {
            charts: newList,
            email: currentDashboardInfo.email,
            id: currentDashboardInfo.id,
            isTemplate: currentDashboardInfo.isTemplate,
            name: currentDashboardInfo.name
        }

        this.setState({ currentDashboardCharts: newList })
        DashboardService.reorderChart(payload).then().catch(err => {
            console.error(err);
            this.setError(err.message);
        })
    }

    /* add, edit, delete dashboards */
    addNewDashboard = (name, owners, members, email, lookbackTime, isPrivate, icmConfigId, icmTeamInfo, icmServiceInfo) => {
        const { showCloneBoardModal, currentDashboardCharts, dashboardList, dashboardIdMap } = this.state
        const isCloning = showCloneBoardModal;
        let charts = [];
        if (isCloning) {
            // If the dashboard is being cloned, the charts of the current dashboard need to be copied to the new dashboard. 
            this.toggleCloneBoardModal();
            charts = currentDashboardCharts.slice(0)
        } else {
            this.toggleAddBoardModal();
        }

        this.setState({
            isInfoLoading: true,
            currentDashboardCharts: [],
            currentDashboardId: null,
            currentDashboardInfo: {}
        })

        DashboardService.addDashboard({
            name: name,
            email: email,
            lookbackTime: lookbackTime,
            charts: charts,
            isPrivate: isPrivate,
            owners: owners,
            members: members,
            icmConfigId: icmConfigId
        }).then(res => {
            let newDashboard = res.data;
            charts = charts.map((chart, index) => {
                chart.id = res.data.charts[index].id;
                chart.dataSuiteId = newDashboard.id;
                return chart
            })

            newDashboard.charts = charts
            newDashboard.icmTeamInfo = icmTeamInfo
            newDashboard.icmServiceInfo = icmServiceInfo

            this.setState({
                dashboardList: [...dashboardList, { label: newDashboard.name, value: newDashboard.id }],
                dashboardIdMap: { ...dashboardIdMap, [newDashboard.id]: newDashboard.name },
            })

            this.switchDashboard(newDashboard.id, newDashboard)
        })
    }

    deleteDashboard = () => {
        this.toggleDeleteBoardModal()
        const { currentDashboardId, dashboardList, currentDashboardInfo } = this.state

        let payload = currentDashboardInfo
        payload.isDeleted = true

        this.data.csvData = {}
        const newList = dashboardList.filter(list => {
            return list.value !== currentDashboardId
        })

        this.setState({
            currentDashboardId: null,
            currentDashboardInfo: {},
            currentDashboardCharts: [],
            dashboardList: newList
        })

        DashboardService.deleteDashboard(payload).then().catch(err => {
            console.error(err);
            this.setError(err.message)
        })

        this.props.history.push('/Dashboard')
    }

    editDashboard = (name, owners, members, emails, lookbackTime, isPrivate, icmConfigId, icmTeamInfo, icmServiceInfo) => {
        this.toggleEditBoardModal()
        const { currentDashboardId, dashboardIdMap, dashboardList, currentDashboardInfo, dailyEndDate } = this.state

        let payload = Object.assign({}, currentDashboardInfo)
        payload.name = name
        payload.email = emails
        payload.lookbackTime = lookbackTime
        payload.isPrivate = isPrivate
        payload.owners = owners
        payload.members = members
        payload.icmConfigId = icmConfigId
        payload.icmTeamInfo = icmTeamInfo
        payload.icmServiceInfo = icmServiceInfo

        dashboardIdMap[currentDashboardId] = name
        const newList = dashboardList.map(list => {
            if (list.value === currentDashboardId) {
                list.label = name
            }
            return list
        })

        this.setState({
            currentDashboardInfo: payload,
            dashboardIdMap: Object.assign({}, dashboardIdMap),
            dashboardList: newList,
        })

        if (currentDashboardInfo.lookbackTime !== lookbackTime) {
            this.setState({
                dailyStartDate: new Date(new Date().setDate(dailyEndDate.getDate() - lookbackTime))
            })
        }

        DashboardService.editDashboard(payload).then().catch(err => {
            console.error(err);
            this.setState({ error: err.message });
        })
    }

    setCsvData = (chartTitle, firstColumnHeader, chartId, data) => {
        let { csvData } = this.data;
        let chartCSVData = getCsvData(chartTitle, firstColumnHeader, chartId, data);
        Object.assign(csvData, chartCSVData);
    }

    buildCsv = () => {
        downloadCsv(this.data.csvData);
        handleClick("downloadCsv", "menu/dashboardTool", "dashboard");
    }

    setError = (error) => {
        this.setState({ error: error });
    }

    setIcmServices = (icmServices) => {
        this.data.icmServices = icmServices;
    }

    renderDeleteDashboardModal = () => {
        const { currentDashboardId, dashboardIdMap, showDeleteBoardModal } = this.state
        return (
            <Modal show={showDeleteBoardModal} onHide={this.toggleDeleteBoardModal}>
                <Modal.Header>
                    Are you sure you want to delete dashboard {dashboardIdMap[currentDashboardId]}?
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.deleteDashboard}>
                        Yes
                    </Button>
                    <Button variant="light" onClick={this.toggleDeleteBoardModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    updateAllDashboardListStates = (dashboardId, isFavorite) => {
        this.setState(prevState => {
            const updatedDashboardList = prevState.dashboardList.map(dashboard => {
                if (dashboard.value === dashboardId) {
                    return { ...dashboard, isFavorite };
                }
                return dashboard;
            });

            return {
                dashboardList: updatedDashboardList
            };
        });
    };

    // Custom option component for the select dropdown
    customOption = (props) => {
        const { data } = props;
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{data.label}</span>
                    <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: data.isFavorite ? 'gold' : 'grey', cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.handleFavoriteClick(data.value);
                        }}
                    />
                </div>
            </components.Option>
        );
    };

    handleFavoriteClick = (dashboardId) => {
        const dashboard = this.state.dashboardList.find(d => d.value === dashboardId);
        if (dashboard) {
            const isFavorite = !dashboard.isFavorite; // Toggle the current favorite status
            if (isFavorite === undefined) return;
            // Optimistically update the favorite status in local state
            this.updateAllDashboardListStates(dashboardId, isFavorite);
            // Update the favorite status on the server
            DashboardService.updateFavoritedDashboards(dashboardId, isFavorite, this.props.loginUser.userName)
                .catch(error => {
                    this.updateAllDashboardListStates(dashboardId, !isFavorite);
                    this.setError(error.message);
                    console.log(error.message);
                });
        }
        return; // Return undefined if dashboard not found
    };

    setError = (error) => {
        this.setState({ error });
    };




    render() {
        const { currentDashboardId, currentDashboardCharts, currentDashboardInfo, dashboardIdMap, dashboardList,
            showAddBoardModal, showEditBoardModal, showAddChartModal, showCloneBoardModal, isListLoading, isInfoLoading, error,
            dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate, noAccessPrompt, isOwner, originator } = this.state;
        const { loginUser, cubes, cosmicServices } = this.props;
        const { cosmicServicesMapping } = this.data;
        const lookbackTime = currentDashboardId && currentDashboardInfo && currentDashboardInfo.lookbackTime ? currentDashboardInfo.lookbackTime : 30;
        const dashboardOptions = [
            { label: 'Favorited', options: dashboardList.filter(dashboard => dashboard.isFavorite) },
            { label: 'Owned', options: dashboardList.filter(dashboard => dashboard.isOwner && !dashboard.isFavorite) },
            { label: 'Member', options: dashboardList.filter(dashboard => !dashboard.isOwner && dashboard.isMember && !dashboard.isFavorite) },
            { label: 'Others', options: dashboardList.filter(dashboard => !dashboard.isFavorite && !dashboard.isOwner && !dashboard.isMember) }
        ];
        const boldGroupLabel = (data) => (
            <div className='boldlabelDashboardListLabels'>
                {data.label}
            </div>
        );

        return (
            <Container fluid>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Row className="dashboardHeader">
                    <Col className="dashboardHeaderItem" md="auto">
                        <Select
                            aria-label="Search dashboards"
                            placeholder="Search dashboards"
                            className="search"
                            value={dashboardIdMap[currentDashboardId] ? { value: currentDashboardId, label: dashboardIdMap[currentDashboardId] } : null}
                            options={dashboardOptions}
                            onChange={this.selectDashboard}
                            formatGroupLabel={boldGroupLabel}
                            components={{
                                Option: this.customOption
                            }} />

                        <Button variant="light" onClick={this.toggleAddBoardModal}>
                            + Dashboard
                        </Button>
                        {!noAccessPrompt && !isInfoLoading && <React.Fragment>
                            {currentDashboardId && isOwner ? <Button variant="light" onClick={this.toggleAddChartModal}>
                                + Chart
                            </Button> : null}
                            {currentDashboardId ? <Dropdown onClick={() => { handleClick("dashboardTool", "menu", "dashboard") }}>
                                <Dropdown.Toggle aria-label="Settings" variant="light" >
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as="button" variant="light" onClick={this.buildCsv}><FontAwesomeIcon icon={faFileCsv} />&ensp;Build CSV</Dropdown.Item>
                                    <Dropdown.Item as="button" variant="light" onClick={this.toggleCloneBoardModal}><FontAwesomeIcon icon={faCopy} />&ensp;Clone</Dropdown.Item>
                                    {isOwner && <React.Fragment>
                                        <Dropdown.Item as="button" variant="light" onClick={this.toggleEditBoardModal}><FontAwesomeIcon icon={faPen} />&ensp;Edit</Dropdown.Item>
                                        <Dropdown.Item as="button" variant="light" onClick={this.toggleDeleteBoardModal}><FontAwesomeIcon icon={faTimes} />&ensp;Delete</Dropdown.Item>
                                    </React.Fragment>}
                                </Dropdown.Menu>
                            </Dropdown> : null}
                            {this.renderDeleteDashboardModal()}
                        </React.Fragment>}
                    </Col>

                    {!noAccessPrompt && !isInfoLoading && <Col className="dashboardHeaderItem" md="auto">
                        <DashboardDatepicker
                            selectDashboardDate={this.selectDashboardDate}
                            lookbackTime={lookbackTime}
                            isListLoading={isListLoading} />
                    </Col>}
                </Row>

                {!noAccessPrompt && !isInfoLoading &&
                    <Row>
                        <Col>
                            <h1 className="dashboardTitle">{currentDashboardInfo ? currentDashboardInfo.name : 'Select Dashboard'}</h1>
                        </Col>
                    </Row>}

                <Row>
                    {isInfoLoading || isListLoading ? <Col><DashboardLoadingOverlay /></Col> : null}
                    {noAccessPrompt && !isInfoLoading ? NotPermittedWarning(noAccessPrompt) : <React.Fragment>
                        {Object.keys(dashboardIdMap).map(dashboardId => (
                            <Route path={"/Dashboard/" + dashboardId}
                                key={dashboardId}
                                render={() =>
                                    <DashboardChartsContainer
                                        cubes={cubes}
                                        cosmicServices={cosmicServices}
                                        cosmicServicesMapping={cosmicServicesMapping}
                                        loginUser={loginUser}
                                        isOwner={isOwner}
                                        dashboardId={currentDashboardId}
                                        chartList={currentDashboardCharts}
                                        dailyStartDate={dailyStartDate}
                                        dailyEndDate={dailyEndDate}
                                        hourlyStartDate={hourlyStartDate}
                                        hourlyEndDate={hourlyEndDate}
                                        originator={originator}
                                        addChart={this.addChart}
                                        editChart={this.editChart}
                                        deleteChart={this.deleteChart}
                                        moveChart={this.moveChart}
                                        dashboardInfo={currentDashboardInfo}
                                        showAddChartModal={showAddChartModal}
                                        toggleAddChartModal={this.toggleAddChartModal}
                                        setCsvData={this.setCsvData}
                                        setError={this.setError}
                                        refreshChartUrl={this.refreshChartUrl}
                                        icmServices={this.data.icmServices}
                                        setIcmServices={this.setIcmServices} />}
                            />))}
                    </React.Fragment>}
                </Row>

                <DashboardAddEditBoardModal
                    showModal={showEditBoardModal || showAddBoardModal || showCloneBoardModal}
                    isEditing={showEditBoardModal}
                    isCloning={showCloneBoardModal}
                    toggleModal={showAddBoardModal ? this.toggleAddBoardModal : (showEditBoardModal ? this.toggleEditBoardModal : this.toggleCloneBoardModal)}
                    name={showEditBoardModal || showCloneBoardModal ? (currentDashboardInfo && currentDashboardInfo.name) || '' : ''}
                    lookbackTime={showEditBoardModal || showCloneBoardModal ? currentDashboardInfo.lookbackTime : 30}
                    isPrivate={showEditBoardModal ? (currentDashboardInfo && currentDashboardInfo.isPrivate) : false}
                    owners={showEditBoardModal ? (currentDashboardInfo && currentDashboardInfo.owners) : [{ GUID: loginUser.id, EmailAddress: loginUser.userName, Type: 'Person', DisplayName: loginUser.displayName }]}
                    members={showEditBoardModal ? (currentDashboardInfo && currentDashboardInfo.members) : []}
                    email={showEditBoardModal || showCloneBoardModal ? (currentDashboardInfo && currentDashboardInfo.email) || [] : []}
                    icmTeamInfo={currentDashboardInfo.icmTeamInfo}
                    icmServiceInfo={currentDashboardInfo.icmServiceInfo}
                    icmConfigId={currentDashboardInfo.icmConfigId}
                    onSubmit={showAddBoardModal || showCloneBoardModal ? this.addNewDashboard : this.editDashboard}
                    icmServices={this.data.icmServices}
                    setIcmServices={this.setIcmServices}
                />
            </Container>
        )
    }
}

export default withRouter(Dashboards)