import React from 'react'
import Col from 'react-bootstrap/Col'
import DashboardChart from './DashboardChart'
import DashboardEditChartModal from './DashboardEditChartModal'
import './Dashboard.css'

function DashboardChartsContainer(props) {
    const { chartList, dailyStartDate, dailyEndDate, hourlyStartDate, hourlyEndDate, cubes, cosmicServices, cosmicServicesMapping, dashboardId, showAddChartModal, toggleAddChartModal, setCsvData, loginUser, isOwner, 
        setError, moveChart, addChart, deleteChart, editChart, refreshChartUrl, dashboardInfo, icmServices, setIcmServices, originator} = props
    const len = chartList.length

    return (
        <React.Fragment>
            {chartList.map(chart => (
                <Col key={chart.id} lg={6} className="dashboardChartContainer">
                    <DashboardChart
                        key={chart.id}
                        cubes={cubes}
                        cosmicServices={cosmicServices}
                        cosmicServicesMapping={cosmicServicesMapping}
                        dashboardId={dashboardId}
                        loginUser={loginUser}
                        isOwner={isOwner}
                        dashboardInfo={dashboardInfo}
                        originator={originator}
                        chart={chart} 
                        totalChartNum={len} 
                        dailyStartDate={dailyStartDate} 
                        dailyEndDate={dailyEndDate} 
                        hourlyStartDate={hourlyStartDate} 
                        hourlyEndDate={hourlyEndDate} 
                        onMoveChart={moveChart} 
                        onEditChartDelete={deleteChart} 
                        onEditChartSave={editChart}
                        setCsvData={setCsvData}
                        refreshChartUrl={refreshChartUrl}
                        icmServices={icmServices}
                        setIcmServices={setIcmServices}
                        />
                </Col>
            ))}
            <DashboardEditChartModal
                    isNewChart 
                    cubes={cubes}
                    showModal={showAddChartModal} 
                    toggleModal={toggleAddChartModal}
                    dashboardId={dashboardId}
                    order={len}
                    onSave={addChart}
                    setError={setError}
                    />
        </React.Fragment>
    )
}

export default DashboardChartsContainer