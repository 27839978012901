import Chart from './Chart';
import SharedCharts from './SharedCharts';
import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Tooltip from 'react-bootstrap/Tooltip'
import DataService from '../Dataservices/DataService'
import PivotHeadPane from './ChartingPaneComponents/PivotHeadPane'
import PivotBodyPane from './ChartingPaneComponents/PivotBodyPane'
import GetRawLogs from './ChartingPaneComponents/GetRawLogs'
import PercentileTable from './PublicComponents/PercentileTable'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { getPercentileTableData, flattenPercentiles } from '../Functions/TableHelper'
import { transformLatencyDataToCumulative, transformLatencyDataToDate, fillInDates, concatData, mergeCheckpointData, emptyLatencySeries, TabInitTimeInterval, fillInEmptySamples, getStartDate, getEndDate, cutDataWithTimeRange, TabMaxTimeInterval, isThisDayIncluded } from '../Functions/ChartHelpers'
import { getTimezoneFromShortName, timezones, convertStringToUTCDate, addDaysToUTCDate, formatUTCDateAsString, formatUTCDateAsDashString } from '../Functions/DateTimeHelper'
import { getPageTitle } from '../Functions/PageTitleHelper'
import './ChartingPane.css'
import InsightsModal from './ChartingPaneComponents/InsightsModal'
import { getNameFromAnalysisUrl, getNameFromCosmicUrl, getGoalLineFromUrl, getChartTypeIndex, getChartTypeFromIndex, getNameFromEvoUrl } from '../Functions/UrlHelper'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EvoDataService from '../Dataservices/EvoDataService.js';
import ChartService, { ScenarioTags } from '../Dataservices/ChartService'
import axios from "axios"
import { handleClick, handleGraph } from '../Dataservices/EventTrackingService'
import config from '../config';
import titles from '../Store/titles.js'
import { CenteredLoadingGif, LoadingOverlay } from './PublicComponents/HelperComponents';
import { library } from "@fortawesome/fontawesome-svg-core";
import { getErrorMessage } from '../Functions/CommonHelper.js';

library.add(faCaretRight);

export class ChartingPane extends Component {


    constructor(props) {
        super(props);

        this.data = {
            cubeName: "",
            cosmicServiceName: "",
            evoServiceName: "",
            headPanePivotServerRequest: {},
            bodyPanePivotServerRequest: {},
            serverNameToUrlMap: {},
            serverNameToDefaultValue: [],
            mapping: {},
            pivots: {},
            autoGraph: false,
            lastFromDate: null,
            lastToDate: null,
            latencyChartData: {
                serverData: [],
                title: "Latency",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            rollingLatencyChartData: {
                serverData: [],
                title: "Latency",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            checkpointChartData: {
                serverData: [],
                rawData: [],
                title: "Checkpoints",
                chartType: "StackedArea",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            checkpointPercentilesChartData: {
                serverData: [],
                title: "Checkpoint Percentiles",
                chartType: "StackedArea",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            metricPercentilesChartDataLatency: {
                serverData: [],
                title: "MetricPercentiles Latency",
                chartType: "Line",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            metricPercentilesChartDataCount: {
                serverData: [],
                title: "MetricPercentiles Count",
                chartType: "Line",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            metricPercentilesChartDataRatio: {
                serverData: [],
                title: "MetricPercentiles Ratio",
                chartType: "Line",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            metricPercentilesChartDataOther: {
                serverData: [],
                title: "MetricPercentiles Other",
                chartType: "Line",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },

            metricChartDataLatency: {
                serverData: [],
                rawData: [],
                title: props.isPivotDynamicallyGenerated ? "Measure Latency" : "Metric Latency",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            metricChartDataCount: {
                serverData: [],
                rawData: [],
                title: props.isPivotDynamicallyGenerated ? "Measure Count" : "Metric Count",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            metricChartDataRatio: {
                serverData: [],
                rawData: [],
                title: "Metric Ratio",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            metricChartDataOther: {
                serverData: [],
                rawData: [],
                title: props.isPivotDynamicallyGenerated ? "Measure Other" : "Metric Other",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            percentilesTabData: {
                serverData: [],
                title: "Percentiles",
                chartType: "Line",
                xAxisType: "category",
                plotOptions: {
                    area: {
                        stacking: 'percent'
                    }
                },
                updatedTime: null,
                upToDate: false
            },
            cumulativeHistogramData: {
                serverData: [],
                title: "Cumulative Histogram",
                chartType: "Line",
                xAxisType: "category",
                plotOptions: {
                    area: {
                        stacking: 'percent'
                    }
                },
                updatedTime: null,
                upToDate: false
            },
            histogramData: {
                serverData: [],
                title: "Histogram",
                chartType: "Line",
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            dailyHistogramData: {
                serverData: [],
                title: "Daily Histogram",
                chartType: "StackedArea",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            ratioData: {
                serverData: [],
                title: "Daily Ratio Trend",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            sampleChartData: {
                serverData: [],
                title: "Sample Count",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            sampleChartDataBar: {
                serverData: [],
                title: "Sample Count",
                chartType: 'Column',
                xAxisType: "category",
                updatedTime: null,
                upToDate: false
            },
            sampleChartDataCheckpoint: {
                serverData: [],
                title: "Sample Count",
                chartType: "Line",
                xAxisType: "datetime",
                updatedTime: null,
                upToDate: false
            },
            tableData: {
                data: [],
                updatedTime: null,
                upToDate: false
            },
            chartInfo: {
                includeWeekends: "true",
                alertList: {}
            },
            selected : {},
            defaultValues : {},
            notFlag: {},
            startTime: new Date().getTime(),
            renderStartTime: null,
            renderEndTime: null,
            progressLoading: {
                progress: [],
                task: "",
                timeInterval: 1,
            },
            dataChange: true,
            dateRangeChange: true,
        }

        this.allowedTabs = {
            Table: 'table',
            PivotTable : 'pivottable',
            Daily : 'daily',
            Daily7Roling: 'daily7Rolling',
            DailyMetrics : 'dailymetrics',
            Checkpoint : 'checkpoint',
            Percentiles : 'percentiles',
            Usage : 'usage',
            Histogram : 'histogram',
            Reliability : '',
            CumulativeHistogram : 'cumulativeHistogram',
            Ratio : 'ratio',
            DailyHistogram : 'dailyHistogram',
            CheckpointPercentiles : 'checkpointPercentiles',
            MetricPercentiles : 'metricPercentiles'
        }

        this.dataQuerySource = axios.CancelToken.source();

        this.state =
        {
            supportPrefix: false,
            dominantBuildOptions: [],
            topTypeOptions: [],
            urlDefaultParams: null,
            metrics: null,
            isGraphLoading: false,
            isLoading: false,
            tab: "daily",
            timezone: "UTC",
            chartType: [],
            bypassCache: "false",
            showInsightsModal: false,
            hasMultipleTrendLines: false,
            isChartingPaneHidden: false,
            error: null,
            isSpecificSeriesExpertInsightsSupported: false,
            isDynamicGeneratedPivotNull : false,
            loadingDay: 0,
            updatedTime: new Date().getTime(),
            showChangeTabTip: false, 
            flightingBanner: false,
            isGraphCanceled: false
        };
    }
    //#endregion

    //#region Error handling
    handleError = (err, errType) => {
        if (err.response && err.response.status === 403) {
            this.setState({
                error: err.response.data,
                isLoading: false
            })
        }else {
            let errorMessage = err.response && err.response.data ? err.response.data : err;
            this.setState({
                error: "Something is wrong when getting " + errType + ". If you see this error constantly or refresh doesn't work, please send an email to o365coreperf@microsoft.com with the following details:" + JSON.stringify(errorMessage),
                isLoading: false
            })
        }
    }
    //#endregion

    //#region Interaction callback functions
    changeTab = (key) => {
        if(this.state.isGraphLoading) {
            this.setState({ showChangeTabTip: !this.state.showChangeTabTip });
        }else {
            handleClick(key, "chartingPane/tab", "analysis");
            this.setState({isGraphLoading: true, tab: key, chartType: []}, () => {
                let currentDisplayedRequest = this.state.currentDisplayedRequest;
                if (currentDisplayedRequest) {
                    this.data.dataChange = true;
                    this.dataQuery(currentDisplayedRequest);
                } else {
                    this.setState({isGraphLoading: false, showChangeTabTip: false});
                }
            })
            let params = new URLSearchParams(this.props.location.search);
            params.set('tab', key);
            params.delete('type');
            this.props.history.push(window.location.pathname + "?" + params.toString());
        }
    }

    changeHeadPanePivot = (key, value) => {
        this.data.headPanePivotServerRequest[key] = value; 
        this.data.dataChange = true;
    }

    changeBodyPanePivot = (key, value) => {
        this.data.bodyPanePivotServerRequest[key] = value;
        this.data.dataChange = true;
    }

    changeChartingPaneDateRange = (key, value) => {
        if (key === 'StartDate' && value > this.data.lastToDate) {
            this.setState({ error: 'Start date must be earlier than the end date.' });
            return;
        }

        if (key === 'EndDate' && value < this.data.lastFromDate) {
            this.setState({ error: 'End date must be after the start date.' });
            return;
        }

        this.data.dateRangeChange = true;
        this.data.headPanePivotServerRequest[key] = value; 
        if (this.props.isPivotDynamicallyGenerated)
        {
            if (key === 'StartDate' && value !== this.data.lastFromDate)
            {
                this.data.lastFromDate = value;
                if (this.data.lastToDate && this.data.lastFromDate)
                {
                    this.getPivotsDynamicallyBasedOnDateRange(this.data.lastFromDate, this.data.lastToDate).then(res => {});
                }
            }
            else if (key === 'EndDate' && value !== this.data.lastToDate)
            {
                this.data.lastToDate = value;
                if (this.data.lastToDate && this.data.lastFromDate)
                {
                    this.getPivotsDynamicallyBasedOnDateRange(this.data.lastFromDate, this.data.lastToDate).then(res => {});
                }
            }
        }
    }
    
    changeChartType = (ind, value) => {
        let {chartType} = this.state
        while(ind >= chartType.length) {
            chartType.push('')
        }
        chartType[ind] = value
        this.setState({chartType: chartType})
        this.addRequestToUrl({...this.data.headPanePivotServerRequest, ...this.data.bodyPanePivotServerRequest})
    }

    changeChartingPaneTimezone = timezone => {
        this.setState({timezone : timezone})
    }

    changeAutoGraph = checked => {
        let params = new URLSearchParams(this.props.location.search);
        params.set('graphIt', checked);
        this.props.history.push(window.location.pathname + "?" + params.toString());
        this.data.autoGraph = checked;
    }
    
    handleGetRawLogsButton = () => {
        this.setState({ showGetRawLogs: true });
        handleClick("getRowLogs", "pivotHeadPane", "analysis");
    }
    handleCloseGetRawLogs = () => this.setState({ showGetRawLogs: false });

    handleInsightsButton = () => {
        let bodyrequest = this.data.bodyPanePivotServerRequest
        let headRequest = this.data.headPanePivotServerRequest
        this.setState({ hasMultipleTrendLines: false });
        for (let key in bodyrequest)
        {
            if (key.toUpperCase().includes("ROLLUP") && bodyrequest[key] === 'false')
            {
                this.setState({ hasMultipleTrendLines: true });
            }
        }
        let percentiles = headRequest.Percentile ? headRequest.Percentile.toString().split(',') : [];
        if (percentiles.length > 1) {
            this.setState({ hasMultipleTrendLines: true });
        }
        this.setState({ showInsightsModal: true });
        handleClick("expertInsight", "pivotHeadPane", "analysis");
    }

    handleCloseInsightModal = () => this.setState({ showInsightsModal: false });

    addPivots = (pivotName, pivots) => {
        let currentValues = this.state.pivots[pivotName];
        let newValues = pivots;
        newValues = currentValues["ExtraPivots"] ? newValues.concat(currentValues["ExtraPivots"]) : newValues;
        let pivotsCopy = JSON.parse(JSON.stringify(this.state.pivots));
        pivotsCopy[pivotName]["ExtraPivots"] = newValues;
        this.setState({pivots: pivotsCopy});
    }

    getLegendSupport = () => { //check if cube has legend support for specific line expert insights
        DataService.post(this.data.cubeName + "/isLegendSupported", {}).then(res=>{
            this.setState({isSpecificSeriesExpertInsightsSupported: res.data})
        }).catch(err => {
            console.log(err);
            console.log("legend support not retrieved for " + this.data.cubeName);
        });
    }
    
    //#endregion

    //#region URL functions
    addRequestToUrl = (request) => {

        var urlMap = this.data.serverNameToUrlMap;

        // We get a blank set of params to reset the url
        var params = new URLSearchParams();

        const axisMap = {
            Latency: 'l',
            Count: 'c',
            Ratio: 'r'
        }

        const chartLineTypeMap = {
            Solid: 's',
            Dash: 'da',
            Dot: 'do',
            DashDot: 'dao'
        }

        params.set('graphIt', this.data.autoGraph);
        if(this.props.isHourly) {
            params.set('tz', timezones[this.state.timezone].shortName);
        }

        const goalLines = this.data.chartInfo.alertList?.GoalLines;
        if(goalLines) 
        {
            const goalLineValue = goalLines.map((goalline) => {
                const goalLine = goalline.GoalLine
                return axisMap[goalLine.Axis] + '-' + goalLine.Goal + '-' + goalLine.Color.substring(1) + '-' + chartLineTypeMap[goalLine.LineType]
            }).join("|")
            params.set('ydl', goalLineValue);
        }

        for(let key in request) 
        {
               // keeps server only bits out of the url (ex. SendServerLog) 
               if(urlMap[key] && (request[key] !== this.data.serverNameToDefaultValue[key] || key === "StartDate" || key === "EndDate"))
               {
                   params.set(urlMap[key], request[key] === null ? '' : request[key]);
               }
        }
        params.set('tab', this.state.tab);
        if(this.state.chartType.length > 0) params.set('type', this.state.chartType.map(chartType => (getChartTypeIndex(chartType))).join("|"));
        this.props.history.push(window.location.pathname + "?" + params.toString());
    }

    parseUrlToState = () => {
        let urlMap = this.data.serverNameToUrlMap;
        let reverseUrlMap = {};
        for(let key in urlMap){
            reverseUrlMap[urlMap[key]] = key;
        }

        let urlDefaultParams = {};
        let metrics = [];

        let params = new URLSearchParams(this.props.location.search);
        let autoGraph = params.get('graphIt') === 'true';
        urlDefaultParams['graphIt'] = autoGraph;
        let timezone = getTimezoneFromShortName(params.get('tz'));
        urlDefaultParams['timezone'] = timezone ? timezone : "UTC";
        timezone = urlDefaultParams['timezone'];
        let chartType = params.get('type')?params.get('type').split("|").map( chartTypeIndex => (getChartTypeFromIndex(chartTypeIndex)) ):[];
        if(chartType.length > 0) urlDefaultParams['type'] = chartType;
        urlDefaultParams['sd'] = this.data.serverNameToDefaultValue['StartDate'];
        urlDefaultParams['ed'] = this.data.serverNameToDefaultValue['EndDate'];

        for(let p of params) {
            let key = p[0];
            let value = p[1];
            if (key === 'ydl') {
                // Add goal line information to chartInfo
                this.data.chartInfo.alertList.GoalLines = getGoalLineFromUrl(value).map(line => ({Id: null, GoalLine: line}));
                continue;
            }

            if (key === 'tab' && value === 'table') {
                value = 'pivottable';
            }

            if(key === 'metric') {
                metrics = value.split(',');
            }
            
            let revKey = reverseUrlMap[key];
            if (revKey) {
                urlDefaultParams[revKey] = value;
            }
            else if (this.props.isPivotDynamicallyGenerated && key !== 'graphIt') {
                // Add dynamically generated pivots to url when applicable
                urlDefaultParams[key] = value;
            }
        }

        let tab = urlDefaultParams["Tab"] ? urlDefaultParams["Tab"] : "daily";
        let bypassCache = urlDefaultParams["BypassCache"] ? urlDefaultParams["BypassCache"] : "false";
        return {autoGraph, metrics, urlDefaultParams, tab, timezone, bypassCache, chartType};
    }
    //#endregion

    //#region progress loading
    
    updateSeries = (tab, res, isHourly, includeWeekends, startDate, endDate) => {
        if(tab === "checkpoint" )
        {
            this.updateData(this.data.sampleChartDataCheckpoint, concatData(this.data.sampleChartDataCheckpoint.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.SampleCountDataSeries), isHourly, includeWeekends, false), 
                startDate, endDate, isHourly, includeWeekends, false), null);
        }
        else if(tab === "usage")
        {
            this.updateData(this.data.sampleChartData, concatData(this.data.sampleChartData.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), isHourly, includeWeekends, false), 
                startDate, endDate, isHourly, includeWeekends, false), 0);
        } 
        else
        {
            this.updateData(this.data.sampleChartData, concatData(this.data.sampleChartData.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.SampleCountDataSeries), isHourly, includeWeekends, false), 
                startDate, endDate, isHourly, includeWeekends, false), null);
        }

        if(tab === "checkpoint")
        {
            let checkpointChartData = this.data.checkpointChartData;
            checkpointChartData.rawData = [...res.data.AdditionalLatencyDataSeries].sort((a, b)=>(parseInt(b.ID) - parseInt(a.ID)));
            this.updateData(checkpointChartData, mergeCheckpointData(checkpointChartData.serverData, 
                fillInDates(transformLatencyDataToDate([...res.data.AdditionalLatencyDataSeries].sort((a, b)=>(parseInt(b.ID) - parseInt(a.ID)))), isHourly, includeWeekends, true), 
                startDate, endDate, isHourly, includeWeekends, true), 0);
        }
        else if(tab === "dailymetrics")
        {
            let metricChartDataLatency = this.data.metricChartDataLatency;
            metricChartDataLatency.rawData = res.data.LatencyDataSeries;
            this.updateData(metricChartDataLatency, concatData(metricChartDataLatency.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
            })), isHourly, includeWeekends, true), startDate, endDate, isHourly, includeWeekends, true), 0);
            
            let metricChartDataCount = this.data.metricChartDataCount;
            metricChartDataCount.rawData = res.data.LatencyDataSeries;
            this.updateData(metricChartDataCount, concatData(metricChartDataCount.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
            })), isHourly, includeWeekends, true), startDate, endDate, isHourly, includeWeekends, true), 1);

            let metricChartDataRatio = this.data.metricChartDataRatio;
            metricChartDataRatio.rawData = res.data.LatencyDataSeries;
            this.updateData(metricChartDataRatio, concatData(metricChartDataRatio.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
            })), isHourly, includeWeekends, true), startDate, endDate, isHourly, includeWeekends, true), 2);

            let metricChartDataOther = this.data.metricChartDataOther;
            metricChartDataOther.rawData = res.data.LatencyDataSeries;
            this.updateData(metricChartDataOther, concatData(metricChartDataOther.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
            })), isHourly, includeWeekends, true), startDate, endDate, isHourly, includeWeekends, true), 3);
        }
        else if (tab === "daily")
        {
            this.updateData(this.data.latencyChartData, concatData(this.data.latencyChartData.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true), 
                startDate, endDate, isHourly, includeWeekends, true), 0);
        }
        else if (tab === "daily7Rolling") {
            this.updateData(this.data.rollingLatencyChartData, concatData(this.data.rollingLatencyChartData.serverData,
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true),
                startDate, endDate, isHourly, includeWeekends, true), 0);
        }
        else if (tab === 'ratio')
        {
            this.updateData(this.data.ratioData, concatData(this.data.ratioData.serverData, 
                fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true), 
                startDate, endDate, isHourly, includeWeekends, true), 0);
        }
    }

    updateProgressWithData = (tab, res) => {
        if(tab === "usage") {
            if(res.data.LatencyDataSeries.length > 0) {
                res.data.LatencyDataSeries[0].Data.map(x => {
                    if(this.data.progressLoading.progress[x.name] !== undefined) 
                        this.data.progressLoading.progress[x.name] = true;
                })
            }
        }else {
            if(res.data.SampleCountDataSeries.length > 0) {
                res.data.SampleCountDataSeries[0].Data.map(x => {
                    if(this.data.progressLoading.progress[x.name] !== undefined) 
                        this.data.progressLoading.progress[x.name] = true;
                })
            }
        }
    }

    updateProgressWithTime = (startDate, endDate) => {
        for(let date = startDate; date <= endDate; date = addDaysToUTCDate(date, 1)){
            if(this.data.progressLoading.progress[formatUTCDateAsString(date)] !== undefined)
                this.data.progressLoading.progress[formatUTCDateAsString(date)] = true;
        }
    }

    emptyLoadingProgress = (startDate, endDate, includeWeekends) => {
        let data = {};
        for(let date = startDate; date <= endDate; date = addDaysToUTCDate(date, 1)){
            if(!isThisDayIncluded(date, includeWeekends)) continue;
            data[formatUTCDateAsString(date)] = false;
        }
        return data;
    }

    newLoadingProgressWithOriginData = (startDate, endDate, includeWeekends) => {
        let data = {};
        for(let date = startDate; date <= endDate; date = addDaysToUTCDate(date, 1)){
            if(!isThisDayIncluded(date, includeWeekends)) continue;
            const dateString = formatUTCDateAsString(date);
            if(this.data.progressLoading.progress[dateString] !== undefined) {
                data[dateString] = this.data.progressLoading.progress[dateString];
            }else {
                data[dateString] = false;
            }
        }
        return data;
    }

    formatSeries = (series) => {
        if(!series.Name)
        {
            series.Name = "(blank)";
        }
        if(this.state.metrics && this.state.metrics.length > 0 && !this.state.metrics.includes(series.Name)){
            series.Hide = true;
        }
        return series;
    }

    updateTaskBar= (message) => {
        this.data.progressLoading.task = message;
        this.setState({updatedTime: new Date().getTime()});
    }

    updateProgressTimeInterval = (timeInterval) => {
        this.data.progressLoading.timeInterval = timeInterval > TabMaxTimeInterval[this.state.tab] ? TabMaxTimeInterval[this.state.tab] : timeInterval;
    }

    queryCacheData = (tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate) => {
        let queryFunction = ChartService.postDataCacheQuery(cubeName, request, token, ScenarioTags.Analysis);
        this.updateTaskBar("Load cached data");
        queryFunction.then(res => {
            if (res.data.IsSuccess === false) {
                // If request failed due to NotImplementedException, it means it's an old cube which can not support cache only query.
                if(res.data.ErrorMessage && res.data.ErrorMessage.includes("System.NotImplementedException")) {
                    this.setState({updatedTime: new Date().getTime()});
                    this.queryNotCacheData(tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate);
                }else {
                    console.error(res.data.ErrorMessage)
                    this.setState({isGraphLoading: false, error: res.data.ErrorMessage, showChangeTabTip: false});
                }
                return;
            }
            res.data.SampleCountDataSeries?.map(series => this.formatSeries(series));
            res.data.LatencyDataSeries?.map(series => this.formatSeries(series));
            res.data.AdditionalLatencyDataSeries?.map(series => this.formatSeries(series));

            this.updateProgressWithData(tab, res);
            this.updateSeries(tab, res, isHourly, includeWeekends, startDate, endDate);
            this.setState({updatedTime: new Date().getTime()});
            this.queryNotCacheData(tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate);
        }).catch((error) => {

            // do error handling here
            if (axios.isCancel(error)) {
                this.setState({isGraphLoading: false, showChangeTabTip: false});
            } else {
                console.error(error)
                this.setState({isGraphLoading: false, error: error.message, showChangeTabTip: false}); 
            }
        })
    }

    queryNotCacheData = (tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate) => {
        // This function is to construct a request to query interval data after cache data finishing loading. 
        // There is two way to call this function, from query cache data function and from query not cache data function
        // 1. If it was called by queryCacheData function, it means that startDate and endDate in request cover total time range
        // and this function need to query not loaded data from endDate to startDate. Will initialize requestEndDate from endDate
        // 2. If it was called by queryNotCacheData function, it means that time range between startDate and endDate in request has been finished
        // and this function need to query previous data. Will initialize requestEndDate from previous day of startDate in request. 
        let requestEndDate = null;
        if(request.StartDate === formatUTCDateAsString(startDate) && request.EndDate === formatUTCDateAsString(endDate)) { // first request after cache query
            requestEndDate = endDate;
        }else {
            requestEndDate = addDaysToUTCDate(convertStringToUTCDate(request.StartDate), -1);
        }

        // If the requestEndDate has been loaded and is larger than startDate, move it to previous day. 
        while((this.data.progressLoading.progress[formatUTCDateAsString(requestEndDate)] || !isThisDayIncluded(requestEndDate, includeWeekends)) && requestEndDate >= startDate) {
            requestEndDate = addDaysToUTCDate(requestEndDate, -1);
        }

        // If requestEndDate is smaller than startDate, it means that all data has been finished. 
        // Clear unused placeholder and stop graphing.
        if(requestEndDate < startDate) {
            this.clearEmptySeries(tab);
            this.updateToDate(tab);
            this.setState({isGraphLoading: false, showChangeTabTip: false});
            return;
        }

        // requestStartDate will use the max(the lastest not loaded day from requestEndDate, the ${timeInterval} day from requestEndDate)
        const timeInterval = this.data.progressLoading.timeInterval;
        let requestStartDate = requestEndDate;
        let intervalStartDate = addDaysToUTCDate(requestEndDate, -(timeInterval-1));
        intervalStartDate = intervalStartDate < startDate ? startDate : intervalStartDate;
        while((!this.data.progressLoading.progress[formatUTCDateAsString(requestStartDate)] || !isThisDayIncluded(requestStartDate, includeWeekends)) && requestStartDate > intervalStartDate) {
            requestStartDate = addDaysToUTCDate(requestStartDate, -1);
        }

        let nextRequest = {...request, StartDate: formatUTCDateAsString(requestStartDate), EndDate: formatUTCDateAsString(requestEndDate)};
        this.updateTaskBar("Query data from " + formatUTCDateAsString(requestStartDate) + " to " + formatUTCDateAsString(requestEndDate));
        this.queryDataInterval(tab, cubeName, nextRequest, token, isHourly, includeWeekends, startDate, endDate);
    }

    queryDataInterval = (tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate) => {
        let queryFunction = ChartService.postDataQuery(cubeName, request, token, ScenarioTags.Analysis);
        queryFunction.then(res => {
            if (res.data.IsSuccess === false) 
            {
                console.error(res.data.ErrorMessage);
                this.setState({isGraphLoading: false, error: res.data.ErrorMessage, showChangeTabTip: false});
                return;
            }

            res.data.SampleCountDataSeries?.map(series => this.formatSeries(series));
            res.data.LatencyDataSeries?.map(series => this.formatSeries(series));
            res.data.AdditionalLatencyDataSeries?.map(series => this.formatSeries(series));

            this.updateSeries(tab, res, isHourly, includeWeekends, startDate, endDate);
            this.updateProgressWithTime(convertStringToUTCDate(request.StartDate), convertStringToUTCDate(request.EndDate));
            this.setState({updatedTime: new Date().getTime()});
            this.queryNotCacheData(tab, cubeName, request, token, isHourly, includeWeekends, startDate, endDate);
        }).catch((error) => {

            // do error handling here
            if (axios.isCancel(error)) {
                this.setState({isGraphLoading: false, showChangeTabTip: false});
            } else {
                console.error(error)
                this.setState({isGraphLoading: false, error: error.message, showChangeTabTip: false}); 
            }
        })
    }

    getIsProgressiveLoading = tab => {
        return (this.data.cubeName !== "perfPanelPerf") &&(!this.props.isHourly) && (!this.props.isPivotDynamicallyGenerated) && (tab === "checkpoint" || tab === "usage" || tab === "dailymetrics" || tab === "daily") // todo : tab === "ratio"
    }

    startLoadingFromGetMaxDate = (tab, request, isHourly, includeWeekends, startDate, endDate, useOriginalData) => {
        this.data.progressLoading.task = "Get max query date";
        this.data.progressLoading.timeInterval = TabInitTimeInterval[tab];
        if(!useOriginalData) this.data.progressLoading.progress = [];
        this.setState({updatedTime: new Date().getTime()});
        ChartService.postMaxDate(this.data.cubeName, null, this.dataQuerySource.token).then(res => {
            const maxDate = convertStringToUTCDate(JSON.parse(res.data).MaxDateNormalized)
            endDate = maxDate < endDate? maxDate : endDate;

            if(useOriginalData) {
                this.data.progressLoading.progress = this.newLoadingProgressWithOriginData(startDate, endDate, includeWeekends);
                this.initDataWithOriginData(request, startDate, endDate);
            }else {
                this.data.progressLoading.progress = this.emptyLoadingProgress(startDate, endDate, includeWeekends);
                this.initDataWithEmptyData(request, startDate, endDate);
            }
            
            request = {...request, StartDate: formatUTCDateAsString(startDate), EndDate: formatUTCDateAsString(endDate)}
            this.queryCacheData(tab, this.data.cubeName, request, this.dataQuerySource.token, isHourly, includeWeekends, startDate, endDate);
        }).catch((error) => {
            // do error handling here
            if (axios.isCancel(error)) {
                this.setState({isGraphLoading: false, showChangeTabTip: false});
            } else {
                console.error(error)
                this.setState({isGraphLoading: false, error: error.message, showChangeTabTip: false}); 
            }
        })
    }

    progressDataQuery = request => {
        let tab = this.state.tab;
        let { isHourly } = this.props;
        let includeWeekends = request["IncludeWeekends"] ? request["IncludeWeekends"].toLowerCase() : "true";
        let startDate = convertStringToUTCDate(request.StartDate), endDate = convertStringToUTCDate(request.EndDate);

        // If there is no change to pivot or tab, or user cancel graphing and restart, original data can be used.
        if(!this.data.dataChange) {
            // Get the start date and end date of the last requet from samples data. Use the last date in previous data as end date and use the first date in previous data as start date. 
            // When tab is checkpoint, samples data is saved in this.data.sampleChartDataCheckpoint
            // When tab is not checkpoint, samples data is saved in this.data.sampleChartData
            const original_end_date = (tab === "checkpoint") ? 
                (this.data.sampleChartDataCheckpoint.serverData.length > 0 ? this.data.sampleChartDataCheckpoint.serverData[0].Data.at(-1).x : addDaysToUTCDate(endDate, -1)) :
                (this.data.sampleChartData.serverData.length > 0 ? this.data.sampleChartData.serverData[0].Data.at(-1).x : addDaysToUTCDate(endDate, -1));
            const original_start_date = (tab === "checkpoint") ? 
                (this.data.sampleChartDataCheckpoint.serverData.length > 0 ? this.data.sampleChartDataCheckpoint.serverData[0].Data.at(0).x : addDaysToUTCDate(startDate, 1)) :
                (this.data.sampleChartData.serverData.length > 0 ? this.data.sampleChartData.serverData[0].Data.at(0).x : addDaysToUTCDate(startDate, 1));
            
            // Check if last request finished. 
            const originalLoadingDay = Object.values(this.data.progressLoading.progress).filter(v => (v)).length;
            const originalTotalDay = Object.keys(this.data.progressLoading.progress).length;
            const lastGraphFinish = ((originalTotalDay !== 0) && (originalTotalDay === originalLoadingDay));

            // If the last request finish, it can be used directly. 
            if(lastGraphFinish){
                if(original_end_date < endDate) { 
                    // 1. original end date is less than new end date. 
                    // Before we fetch data between original end date and new end date, we need to fetch the max end date of cube to know the real end date of data. 
                    // After get real end date of cube, originial data can be used to initialize the chart.
                    this.startLoadingFromGetMaxDate(tab, request, isHourly, includeWeekends, startDate, endDate, true);
                }else if(original_start_date <= startDate){
                    // 2. original end date is greater than or equal to new end date and original start date is less than or equal to new start date, in other words, new request is subset of original request.
                    // Original data is enough to update chart. 
                    this.data.progressLoading.progress = this.newLoadingProgressWithOriginData(startDate, endDate);
                    this.initDataWithOriginData(request, startDate, endDate);
                    this.clearEmptySeries(tab);
                    this.setState({isGraphLoading: false, currentDisplayedRequest: request, showChangeTabTip: false});
                }else {  
                    // 3. original end date is greater than or equal to new end date and original start date is greater than new start date. 
                    // We don't need to get max date and only need to query data between new start date and original start date or the new end date
                    this.data.progressLoading.progress = this.newLoadingProgressWithOriginData(startDate, endDate);
                    this.data.progressLoading.timeInterval = TabInitTimeInterval[tab];
                    this.initDataWithOriginData(request, startDate, endDate);
                    endDate = endDate < original_start_date?endDate:original_start_date;
                    request = {...request, StartDate: formatUTCDateAsString(startDate), EndDate: formatUTCDateAsString(endDate)}
                    this.queryCacheData(tab, this.data.cubeName, request, this.dataQuerySource.token, isHourly, includeWeekends, startDate, endDate);
                }
            }
            // The original data may be missing if the user cancel graph. Need to fillin missing data in this case.
            // Will start from either getting max date or getting cache. 
            else{
                if(original_end_date < endDate || originalTotalDay === 0) { 
                    // 1. original end date is less than new end date or user cancel last graph request before getting max date finishing. 
                    // Send a getting max date query. Initialize chart with original data. Missing data in orignial chart will be filled during following processing.
                    this.startLoadingFromGetMaxDate(tab, request, isHourly, includeWeekends, startDate, endDate, true);
                }else{
                    // 2. No need to update max date. In this case, loading can start from getting cache data.
                    this.data.progressLoading.progress = this.newLoadingProgressWithOriginData(startDate, endDate);
                    this.data.progressLoading.timeInterval = TabInitTimeInterval[tab];
                    this.initDataWithOriginData(request, startDate, endDate);
                    request = {...request, StartDate: formatUTCDateAsString(startDate), EndDate: formatUTCDateAsString(endDate)}
                    this.queryCacheData(tab, this.data.cubeName, request, this.dataQuerySource.token, isHourly, includeWeekends, startDate, endDate);
                }
            }
        }
        // If user change a pivot or tab, all data need to be reload. 
        else {
            this.startLoadingFromGetMaxDate(tab, request, isHourly, includeWeekends, startDate, endDate, false);
        }
    }

    initDataWithEmptyData = (request, startDate, endDate) => {
        let tab = this.state.tab;
        let { isHourly } = this.props;
        let includeWeekends = request["IncludeWeekends"] ? request["IncludeWeekends"].toLowerCase() : "true";
        if(tab === "checkpoint" )
        {
            this.updateData(this.data.sampleChartDataCheckpoint, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false), null);
        }
        else if(tab === "usage")
        {
            this.updateData(this.data.sampleChartData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false), 0);
        } 
        else
        {
            this.updateData(this.data.sampleChartData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false), null);
        }

        if(tab === "checkpoint")
        {
            this.updateData(this.data.checkpointChartData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 0);
        }
        else if(tab === "dailymetrics")
        {
            this.updateData(this.data.metricChartDataLatency, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 0);
            this.updateData(this.data.metricChartDataCount, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 1);
            this.updateData(this.data.metricChartDataRatio, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 2);
            this.updateData(this.data.metricChartDataOther, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 3);
        }
        else if (tab === "daily")
        {
            this.updateData(this.data.latencyChartData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 0);
        }
        else if (tab === "daily7Rolling") {
            this.updateData(this.data.rollingLatencyChartData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 0);
        } 
        else if(tab === 'ratio') // todo: not support from backend now
        {
            this.updateData(this.data.ratioData, fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 0);
        }
    }

    initDataWithOriginData = (request, startDate, endDate) => {
        let tab = this.state.tab;
        let { isHourly } = this.props;
        let includeWeekends = request["IncludeWeekends"] ? request["IncludeWeekends"].toLowerCase() : "true";
        if(tab === "checkpoint" )
        {
            this.updateData(this.data.sampleChartDataCheckpoint, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false), 
                this.data.sampleChartDataCheckpoint.serverData, startDate, endDate, isHourly, includeWeekends, false), null);
        }
        else
        {
            this.updateData(this.data.sampleChartData, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, false), 
                this.data.sampleChartData.serverData, startDate, endDate, isHourly, includeWeekends, false), null);
        } 

        if(tab === "checkpoint")
        {
            this.updateData(this.data.checkpointChartData, mergeCheckpointData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.checkpointChartData.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
        }
        else if(tab === "dailymetrics")
        {
            this.updateData(this.data.metricChartDataLatency, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.metricChartDataLatency.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
            this.updateData(this.data.metricChartDataCount, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.metricChartDataCount.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
            this.updateData(this.data.metricChartDataRatio, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.metricChartDataRatio.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
            this.updateData(this.data.metricChartDataOther, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.metricChartDataOther.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
        }
        else if(tab === "daily")
        {
            this.updateData(this.data.latencyChartData, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.latencyChartData.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
        } 
        else if(tab === 'ratio') // todo: not support from backend now
        {
            this.updateData(this.data.ratioData, concatData(fillInDates(emptyLatencySeries(startDate, endDate, includeWeekends), isHourly, includeWeekends, true), 
                this.data.ratioData.serverData, startDate, endDate, isHourly, includeWeekends, true), null);
        }
    }

    clearEmptySeries = (tab) => {
        // Some date time may not have data, we need to trim the blank date
        const newStartDate = getStartDate((tab === "checkpoint") ? this.data.sampleChartDataCheckpoint.serverData : this.data.sampleChartData.serverData,);
        const newEndDate = getEndDate((tab === "checkpoint") ? this.data.sampleChartDataCheckpoint.serverData : this.data.sampleChartData.serverData);

        // When user cancel graphIt, we need to clear placeholder series but keep all unloaded data as null.
        // When this function is called when finishing loading, we need to clear placeholder series and fill in null samples with 0
        if(tab === "checkpoint" )
        {
            this.updateData(this.data.sampleChartDataCheckpoint, 
                fillInEmptySamples(cutDataWithTimeRange(this.data.sampleChartDataCheckpoint.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")))
                , null);
        }else
        {
            this.updateData(this.data.sampleChartData, 
                fillInEmptySamples(cutDataWithTimeRange(this.data.sampleChartData.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")))
                , null);
        }

        if(tab === "checkpoint")
        {
            this.updateData(this.data.checkpointChartData, cutDataWithTimeRange(this.data.checkpointChartData.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
        }
        else if(tab === "dailymetrics")
        {
            this.updateData(this.data.metricChartDataLatency, cutDataWithTimeRange(this.data.metricChartDataLatency.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
            this.updateData(this.data.metricChartDataCount, cutDataWithTimeRange(this.data.metricChartDataCount.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
            this.updateData(this.data.metricChartDataRatio, cutDataWithTimeRange(this.data.metricChartDataRatio.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
            this.updateData(this.data.metricChartDataOther, cutDataWithTimeRange(this.data.metricChartDataOther.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
        }
        else if (tab === "daily")
        {
            this.updateData(this.data.latencyChartData, cutDataWithTimeRange(this.data.latencyChartData.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
        } 
        else if (tab === "daily7Rolling") {
            this.updateData(this.data.rollingLatencyChartData, cutDataWithTimeRange(this.data.latencyChartData.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
        }
        else if(tab === 'ratio')
        {
            this.updateData(this.data.ratioData, cutDataWithTimeRange(this.data.ratioData.serverData, newStartDate, newEndDate).filter(x => (x.ID !== "0")), null);
        }
    }

    updateData = (data, newData, chartTypeIndex) => {
        data.updatedTime = new Date().getTime();
        data.serverData = newData;
        if(chartTypeIndex !== null && this.state.chartType.length > chartTypeIndex && this.state.chartType[chartTypeIndex]) {
            data.chartType = this.state.chartType[chartTypeIndex];
        }
    }

    updateToDate = (tab) => {
        if(tab === "checkpoint")
        {
            this.data.checkpointChartData.upToDate = true;
            this.data.sampleChartDataCheckpoint.upToDate = true;
        }
        else if(tab === "dailymetrics")
        {
            this.data.metricChartDataLatency.upToDate = true;
            this.data.metricChartDataCount.upToDate = true;
            this.data.metricChartDataRatio.upToDate = true;
            this.data.metricChartDataOther.upToDate = true;
            this.data.sampleChartData.upToDate = true;
        }
        else if(tab === "usage")
        {
            this.data.sampleChartData.upToDate = true;
        }
        else if(tab === 'ratio') 
        {
            this.data.ratioData.upToDate = true;
            this.data.sampleChartData.upToDate = true;
        }
        else
        {
            this.data.latencyChartData.upToDate  = true;
            this.data.sampleChartData.upToDate = true;
        }
    }
    //#endregion

    //#region Server queries

    dataQuery = request => {
        let tab = this.state.tab;
        let startTime = new Date().getTime();
        request["Tab"] = tab;
        if(tab === "cumulativeHistogram")
        {
            request["Tab"] = "histogram"
        }

        // check if the chart is up to date
        let upToDate = false;
        if(tab === "checkpoint")
        {
            let checkpointChartData = this.data.checkpointChartData;
            let sampleChartDataCheckpoint = this.data.sampleChartDataCheckpoint;
            upToDate = checkpointChartData.upToDate && sampleChartDataCheckpoint.upToDate;
        }
        else if(tab === "checkpointPercentiles")
        {
            let checkpointChartData = this.data.checkpointPercentilesChartData;
            let sampleChartDataCheckpoint = this.data.sampleChartDataBar;
            upToDate = checkpointChartData.upToDate && sampleChartDataCheckpoint.upToDate;
        }
        else if(tab === "metricPercentiles")
        {
            let metricChartDataLatency = this.data.metricPercentilesChartDataLatency;
            let metricChartDataCount = this.data.metricPercentilesChartDataCount;
            let metricChartDataRatio = this.data.metricPercentilesChartDataRatio;
            let metricChartDataOther = this.data.metricPercentilesChartDataOther;
            upToDate = metricChartDataLatency.upToDate && metricChartDataCount.upToDate &&
                       metricChartDataRatio.upToDate && metricChartDataOther.upToDate;
        }
        else if(tab === "dailymetrics")
        {
            let metricChartDataLatency = this.data.metricChartDataLatency;
            let metricChartDataCount = this.data.metricChartDataCount;
            let metricChartDataRatio = this.data.metricChartDataRatio;
            let metricChartDataOther = this.data.metricChartDataOther;
            upToDate = metricChartDataLatency.upToDate && metricChartDataCount.upToDate &&
                       metricChartDataRatio.upToDate && metricChartDataOther.upToDate;
        }
        else if(tab === "percentiles")
        {                
            let sampleData = this.data.sampleChartDataBar;
            let percentilesTabData = this.data.percentilesTabData;
            upToDate = sampleData.upToDate && percentilesTabData.upToDate;
        }
        else if(tab === "histogram")
        {
            let histogramData = this.data.histogramData;
            upToDate = histogramData.upToDate;
        }
        else if(tab === "dailyHistogram")
        {
            let dailyHistogramData = this.data.dailyHistogramData;
            upToDate = dailyHistogramData.upToDate;
        }
        else if(tab === "cumulativeHistogram")
        {
            let cumulativeHistogramData = this.data.cumulativeHistogramData;
            upToDate = cumulativeHistogramData.upToDate;
        }
        else if(tab === "pivottable")
        {
            upToDate = this.data.tableData.upToDate;
        }
        else if(tab === "usage")
        {
            upToDate = this.data.sampleChartData.upToDate;
        }
        else if(tab === 'ratio') 
        {
            upToDate = this.data.ratioData.upToDate;
        }
        else if (tab === 'daily7Rolling')
        {
            let latencyChartData = this.data.rollingLatencyChartData;
            upToDate = latencyChartData.upToDate;
        }
        else
        {
            let latencyChartData = this.data.latencyChartData;
            upToDate = latencyChartData.upToDate;
        }

        if(upToDate) {
            this.setState({isGraphLoading: false, showChangeTabTip: false});
            return;
        }

        let percentiles = request.Percentile ? request.Percentile.toString().split(',') : [];
        percentiles = flattenPercentiles(percentiles);
        let includeWeekends = request["IncludeWeekends"] ? request["IncludeWeekends"].toLowerCase() : "true";
        this.data.chartInfo['includeWeekends'] = includeWeekends;

        const isProgressLoad = this.getIsProgressiveLoading(tab);
        if(isProgressLoad) {
            this.setState({currentDisplayedRequest: request})
            this.progressDataQuery(request)
        }else {
            let queryFunction;
            if (this.props.isEvo) {
                let evoRequest = {
                    serviceId: request.ServiceId,
                    logId: request.LogId,
                    startDate: formatUTCDateAsDashString(convertStringToUTCDate(request.StartDate)),
                    endDate: formatUTCDateAsDashString(convertStringToUTCDate(request.EndDate)),
                    typeofBuild: request.TypeOfBuild ? request.TypeOfBuild : "none",
                    includeWeekends: includeWeekends,
                    percentile: request.percentile,
                    tab: request.Tab,
                    filters: request.filters,
                }
                queryFunction = EvoDataService.evoLatencyQuery(evoRequest, this.dataQuerySource.token);
            }
            else if (this.props.isPivotDynamicallyGenerated) {
                queryFunction = EvoDataService.latencyQuery(request);
            }
            else
            {
                queryFunction = ChartService.postDataQuery(this.data.cubeName, request, this.dataQuerySource.token, ScenarioTags.Analysis);
            }

            queryFunction.then(res => {
                let { isHourly } = this.props;
                let queryTime = new Date().getTime() - startTime;
                if (res.data.IsSuccess === false) {
                    console.error(res.data.ErrorMessage)
                    this.setState({isGraphLoading: false, error: res.data.ErrorMessage, showChangeTabTip: false});
                    return;
                }

                res.data.SampleCountDataSeries?.forEach(series => this.formatSeries(series));
                res.data.LatencyDataSeries?.forEach(series => this.formatSeries(series));
                res.data.AdditionalLatencyDataSeries?.forEach(series => this.formatSeries(series));

                // get sample chart data
                if(tab === "percentiles" || tab === "pivottable" || tab === "checkpointPercentiles" || tab === "metricPercentiles")
                {
                    let sampleChartDataBar = this.data.sampleChartDataBar;
                    sampleChartDataBar.serverData = res.data.SampleCountDataSeries;
                    sampleChartDataBar.chartType = 'Column';
                    sampleChartDataBar.updatedTime = new Date().getTime();
                    sampleChartDataBar.upToDate = true;
                }
                else if(tab === "checkpoint" )
                {
                    let sampleChartDataCheckpoint = this.data.sampleChartDataCheckpoint;
                    sampleChartDataCheckpoint.serverData = fillInDates(transformLatencyDataToDate(res.data.SampleCountDataSeries), isHourly, includeWeekends, false);
                    sampleChartDataCheckpoint.updatedTime = new Date().getTime();
                    sampleChartDataCheckpoint.upToDate = true;
                }
                else if(tab === "usage")
                {
                    let sampleChartData = this.data.sampleChartData;
                    sampleChartData.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), isHourly, includeWeekends, false);
                    sampleChartData.updatedTime = new Date().getTime();
                    sampleChartData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) sampleChartData.chartType = this.state.chartType[0];
                } 
                else
                {
                    let sampleChartData = this.data.sampleChartData;
                    sampleChartData.serverData = res.data.SampleCountDataSeries ? fillInDates(transformLatencyDataToDate(res.data.SampleCountDataSeries), isHourly, includeWeekends, false) : [];
                    sampleChartData.updatedTime = new Date().getTime();
                    sampleChartData.upToDate = true;
                }
                
                if(tab === "checkpoint")
                {
                    let checkpointChartData = this.data.checkpointChartData;
                    checkpointChartData.rawData = [...res.data.AdditionalLatencyDataSeries].reverse();
                    checkpointChartData.serverData = fillInDates(transformLatencyDataToDate([...res.data.AdditionalLatencyDataSeries].reverse()), isHourly, includeWeekends, true);
                    checkpointChartData.updatedTime = new Date().getTime();
                    checkpointChartData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) checkpointChartData.chartType = this.state.chartType[0];
                }
                else if(tab === "checkpointPercentiles")
                {
                    let checkpointChartData = this.data.checkpointPercentilesChartData;
                    checkpointChartData.serverData = [...res.data.AdditionalLatencyDataSeries].reverse();
                    checkpointChartData.updatedTime = new Date().getTime();
                    checkpointChartData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) checkpointChartData.chartType = this.state.chartType[0];
                }
                else if(tab === "metricPercentiles")
                {
                    let metricChartDataLatency = this.data.metricPercentilesChartDataLatency;
                    metricChartDataLatency.updatedTime = new Date().getTime();
                    metricChartDataLatency.serverData = res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
                    });
                    metricChartDataLatency.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) metricChartDataLatency.chartType = this.state.chartType[0];
                    
                    let metricChartDataCount = this.data.metricPercentilesChartDataCount;
                    metricChartDataCount.updatedTime = new Date().getTime();
                    metricChartDataCount.serverData = res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
                    });
                    metricChartDataCount.upToDate = true;
                    if(this.state.chartType.length > 1 && this.state.chartType[1]) metricChartDataCount.chartType = this.state.chartType[1];

                    let metricChartDataRatio = this.data.metricPercentilesChartDataRatio;
                    metricChartDataRatio.updatedTime = new Date().getTime();
                    metricChartDataRatio.serverData = res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
                    });
                    metricChartDataRatio.upToDate = true;
                    if(this.state.chartType.length > 2 && this.state.chartType[2]) metricChartDataRatio.chartType = this.state.chartType[2];

                    let metricChartDataOther = this.data.metricPercentilesChartDataOther;
                    metricChartDataOther.updatedTime = new Date().getTime();
                    metricChartDataOther.serverData = res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
                    });
                    metricChartDataOther.upToDate = true;
                    if(this.state.chartType.length > 3 && this.state.chartType[3]) metricChartDataOther.chartType = this.state.chartType[3];
                }
                else if(tab === "dailymetrics")
                {
                    let metricChartDataLatency = this.data.metricChartDataLatency;
                    metricChartDataLatency.updatedTime = new Date().getTime();
                    metricChartDataLatency.rawData = res.data.LatencyDataSeries;
                    metricChartDataLatency.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".T");
                    })), isHourly, includeWeekends, true);
                    metricChartDataLatency.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) metricChartDataLatency.chartType = this.state.chartType[0];
                    
                    let metricChartDataCount = this.data.metricChartDataCount;
                    metricChartDataCount.updatedTime = new Date().getTime();
                    metricChartDataCount.rawData = res.data.LatencyDataSeries;
                    metricChartDataCount.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".C");
                    })), isHourly, includeWeekends, true);
                    metricChartDataCount.upToDate = true;
                    if(this.state.chartType.length > 1 && this.state.chartType[1]) metricChartDataCount.chartType = this.state.chartType[1];

                    let metricChartDataRatio = this.data.metricChartDataRatio;
                    metricChartDataRatio.updatedTime = new Date().getTime();
                    metricChartDataRatio.rawData = res.data.LatencyDataSeries;
                    metricChartDataRatio.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() === (".R");
                    })), isHourly, includeWeekends, true);
                    metricChartDataRatio.upToDate = true;
                    if(this.state.chartType.length > 2 && this.state.chartType[2]) metricChartDataRatio.chartType = this.state.chartType[2];

                    let metricChartDataOther = this.data.metricChartDataOther;
                    metricChartDataOther.updatedTime = new Date().getTime();
                    metricChartDataOther.rawData = res.data.LatencyDataSeries;
                    metricChartDataOther.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries.filter(value => {
                        return value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".R") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".C") && value.Name.substring(value.Name.lastIndexOf(".")).toUpperCase().trim() !== (".T");
                    })), isHourly, includeWeekends, true);
                    metricChartDataOther.upToDate = true;
                    if(this.state.chartType.length > 3 && this.state.chartType[3]) metricChartDataOther.chartType = this.state.chartType[3];
                }
                else if(tab === "percentiles")
                {                
                    let percentilesTabData = this.data.percentilesTabData;
                    percentilesTabData.updatedTime = new Date().getTime();
                    percentilesTabData.serverData = res.data.LatencyDataSeries;
                    percentilesTabData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) percentilesTabData.chartType = this.state.chartType[0];
                }
                else if(tab === "histogram")
                {
                    let histogramData = this.data.histogramData;
                    histogramData.updatedTime = new Date().getTime();
                    histogramData.serverData = res.data.LatencyDataSeries;
                    histogramData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) histogramData.chartType = this.state.chartType[0];
                }
                else if(tab === "dailyHistogram")
                {
                    let dailyHistogramData = this.data.dailyHistogramData;
                    dailyHistogramData.updatedTime = new Date().getTime();
                    dailyHistogramData.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), isHourly, includeWeekends, true);
                    dailyHistogramData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) dailyHistogramData.chartType = this.state.chartType[0];
                }
                else if(tab === "cumulativeHistogram")
                {
                    let cumulativeHistogramData = this.data.cumulativeHistogramData;
                    cumulativeHistogramData.updatedTime = new Date().getTime();
                    cumulativeHistogramData.serverData = transformLatencyDataToCumulative(res.data.LatencyDataSeries);
                    cumulativeHistogramData.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) cumulativeHistogramData.chartType = this.state.chartType[0];
                }
                else if(tab === "pivottable")
                {
                    let tableData = this.data.tableData;
                    tableData.updatedTime = new Date().getTime();
                    let cols = percentiles.map(e=>{
                        if(e === "AVG") {
                            return "-1";
                        } else {
                            return parseFloat(e).toString();
                        }
                    })
                    let latencyHeader = percentiles.map(e=>{
                        let p = parseFloat(e);
                        if(isNaN(p)){
                            return e;
                        }else{
                            return p+"%";
                        }
                    })
                    let table = getPercentileTableData(res.data, cols);
                    tableData.table = table;
                    tableData.latencyHeader = latencyHeader;
                    tableData.upToDate = true;
                }
                else if (tab === "daily")
                {
                    let data = this.data.latencyChartData;
                    data.updatedTime = new Date().getTime();
                    data.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true);
                    data.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) data.chartType = this.state.chartType[0];
                }
                else if (tab === "daily7Rolling") {
                    let data = this.data.rollingLatencyChartData;
                    data.updatedTime = new Date().getTime();
                    data.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true);
                    data.upToDate = true;
                    if (this.state.chartType.length > 0 && this.state.chartType[0]) data.chartType = this.state.chartType[0];
                } 
                else if(tab === 'ratio')
                {
                    let data = this.data.ratioData;
                    data.updatedTime = new Date().getTime();
                    data.serverData = fillInDates(transformLatencyDataToDate(res.data.LatencyDataSeries), this.props.isHourly, includeWeekends, true);
                    data.upToDate = true;
                    if(this.state.chartType.length > 0 && this.state.chartType[0]) data.chartType = this.state.chartType[0];
                }

            this.setState({ isGraphLoading: false, currentDisplayedRequest: request, error: null, showChangeTabTip: false });

            let renderTime = this.data.renderEndTime - this.data.renderStartTime;
            handleGraph(this.data.cubeName, tab, "analysis", queryTime, renderTime);

            }).catch((error) => {
                // do error handling here
                if (axios.isCancel(error)) {
                    this.setState({isGraphLoading: false, showChangeTabTip: false});
                } else {
                    console.error(getErrorMessage(error, true));
                    this.setState({isGraphLoading: false, error: getErrorMessage(error, false), showChangeTabTip: false}); 
                }
            });
        }

        // data change and date range change set to false when starting data query
        this.data.dataChange = false;
        this.data.dateRangeChange = false;
    }

    initializeServerRequest = () => {
        if(this.data.mapping && this.data.mapping.groupsList) {
            var headpaneServerRequest = {};
            var bodypaneServerRequest = {};
            var serverNameToUrlMap = {};
            var serverNameToDefaultValue = {};
            
            this.data.mapping.groupsList.map(group => (
                group.pivots.map(pivot => {
                    // set default value to request
                    if(pivot.serverName)
                    {
                        serverNameToUrlMap[pivot.serverName] = pivot.paramName;
                        if(group.groupName !== 'None'){
                            bodypaneServerRequest[pivot.serverName] = null;
                            serverNameToDefaultValue[pivot.serverName] = pivot.defaultVal.length === 0 ? null : pivot.defaultVal.map(t => '"' + t + '"').join('|');
                        }else{
                            headpaneServerRequest[pivot.serverName] = null;
                            serverNameToDefaultValue[pivot.serverName] = pivot.defaultVal.toString();
                        }
                    }
                    if(pivot.rollUp)
                    {
                        if (group.groupName !== 'None')
                        {
                            bodypaneServerRequest[pivot.serverName] = "true";
                        }
                        else
                        {
                            headpaneServerRequest[pivot.serverName] = "true";
                        }

                        serverNameToUrlMap[pivot.rollUp.serverName] = pivot.rollUp.paramName;
                        serverNameToDefaultValue[pivot.rollUp.serverName] = pivot.rollUp.defaultVal.toString();
                    }
                    return pivot;
                })
            ));
            
            headpaneServerRequest["Debug"] = "false";
            headpaneServerRequest["Role"] = null;
            headpaneServerRequest["SendServerLog"] = "true";

            this.data.headPanePivotServerRequest = headpaneServerRequest;
            this.data.bodyPanePivotServerRequest = bodypaneServerRequest;
            this.data.serverNameToUrlMap = serverNameToUrlMap;
            this.data.serverNameToDefaultValue = serverNameToDefaultValue;
        }
    }

    //#endregion

    renderTabList = () => {
        const { chartInfo } = this.data;
        const { loginUser, isHourly, isPivotDynamicallyGenerated } = this.props
        const { showChangeTabTip, isGraphLoading } = this.state
        let metricsSharedChartData = isPivotDynamicallyGenerated ? [this.data.metricChartDataOther, this.data.metricChartDataLatency, this.data.metricChartDataCount]
                        : [this.data.metricChartDataLatency, this.data.metricChartDataCount, this.data.metricChartDataRatio, this.data.metricChartDataOther];
        let metricTabNames = isPivotDynamicallyGenerated ? ["dailymetricsother", "dailymetricslatency", "dailymetricscount"] : ["dailymetricslatency", "dailymetricscount", "dailymetricsratio", "dailymetricsother"];

        if (this.data.mapping && this.data.mapping.tabsList)
        {            
            return <React.Fragment>
                {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                {this.props.description && <Alert variant='info'>{this.props.description}</Alert>}
                <OverlayTrigger placement="top" show={isGraphLoading && showChangeTabTip} overlay={<Tooltip id={`tooltip-changetab`}> If you want to switch tabs, cancel the ongoing graphing or wait for it to complete. </Tooltip>}>
                    <Tabs activeKey={this.state.tab} defaultActiveKey="daily" id="chart-tabs" onSelect={this.changeTab}>
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Daily) >= 0 && <Tab eventKey="daily" title="Daily" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.latencyChartData} tab="daily" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.latencyChartData.updatedTime} updatedGoallineTime={this.data.latencyChartData.updatedTime} chartInfo={chartInfo} alternateSortData={this.data.sampleChartData} isSpecificSeriesExpertInsightsSupported={this.state.isSpecificSeriesExpertInsightsSupported} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} updatedGoallineTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} alternateSortData={this.data.latencyChartData} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Daily7Roling) >= 0 && <Tab eventKey="daily7Rolling" title="Daily 7 Rolling" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.rollingLatencyChartData} tab="daily7Rolling" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.rollingLatencyChartData.updatedTime} chartInfo={chartInfo} alternateSortData={this.data.sampleChartData} isSpecificSeriesExpertInsightsSupported={this.state.isSpecificSeriesExpertInsightsSupported} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} alternateSortData={this.data.latencyChartData} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.PivotTable) >= 0 && <Tab style={{ width: '100%' }} eventKey="pivottable" title="Table" mountOnEnter>
                            <PercentileTable style={{ width: '100%' }} tableData={this.data.tableData} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Usage) >= 0 && <Tab eventKey="usage" title="Usage" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} updatedGoallineTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Checkpoint) >= 0 && <Tab eventKey="checkpoint" title="Checkpoint" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.checkpointChartData} tab="checkpoint" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.checkpointChartData.updatedTime} updatedGoallineTime={this.data.checkpointChartData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartDataCheckpoint} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartDataCheckpoint.updatedTime} updatedGoallineTime={this.data.sampleChartDataCheckpoint.updatedTime} chartInfo={chartInfo} />
                        </Tab>}            
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.DailyMetrics) >= 0 && <Tab eventKey="dailymetrics" title={isPivotDynamicallyGenerated ? "Measure" : "Metrics"} mountOnEnter>
                            <SharedCharts isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} chartData={metricsSharedChartData} tabs={metricTabNames} cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.metricChartDataLatency.updatedTime} updatedGoallineTime={this.data.metricChartDataLatency.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} updatedGoallineTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Percentiles) >= 0 && <Tab eventKey="percentiles" title="Percentiles" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.percentilesTabData} tab="percentiles" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.percentilesTabData.updatedTime} chartInfo={chartInfo} alternateSortData={this.data.sampleChartDataBar} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartDataBar} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartDataBar.updatedTime} alternateSortData={this.data.percentilesTabData} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.CheckpointPercentiles) >= 0 && <Tab eventKey="checkpointPercentiles" title="Checkpoint Percentiles" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.checkpointPercentilesChartData} tab="checkpointPercentiles" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.checkpointPercentilesChartData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartDataBar} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartDataBar.updatedTime} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.MetricPercentiles) >= 0 && <Tab eventKey="metricPercentiles" title={isPivotDynamicallyGenerated ? "Measure Percentiles" : "Metric Percentiles"} mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.metricPercentilesChartDataLatency} tab="metricspercentileslatency" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.metricPercentilesChartDataLatency.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.metricPercentilesChartDataCount} tab="metricspercentilescount" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.metricPercentilesChartDataCount.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 1)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.metricPercentilesChartDataRatio} tab="metricspercentilesratio" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.metricPercentilesChartDataRatio.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 2)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.metricPercentilesChartDataOther} tab="metricspercentilesother" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.metricPercentilesChartDataOther.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 3)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartDataBar} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartDataBar.updatedTime} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.CumulativeHistogram) >= 0 && <Tab eventKey="cumulativeHistogram" title="Cumulative Histogram" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.cumulativeHistogramData} tab="cumulativeHistogram" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.cumulativeHistogramData.updatedTime} alternateSortData={this.data.sampleChartData} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} alternateSortData={this.data.cumulativeHistogramData} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Histogram) >= 0 && <Tab eventKey="histogram" title="Histogram" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} options={this.data.histogramData} tab="histogram" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.histogramData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} isHourly={isHourly} timezone={this.state.timezone} updatedTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.DailyHistogram) >= 0 && <Tab eventKey="dailyHistogram" title="Daily Histogram" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.dailyHistogramData} tab="dailyHistogram" cube={this.data.cubeName} updatedTime={this.data.dailyHistogramData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.sampleChartData} tab="usage" cube={this.data.cubeName} updatedTime={this.data.sampleChartData.updatedTime} chartInfo={chartInfo} />
                        </Tab>}
                        {this.data.mapping.tabsList.indexOf(this.allowedTabs.Ratio) >= 0 && <Tab eventKey="ratio" title="Ratio" mountOnEnter>
                            <Chart isChartingPaneHidden={this.state.isChartingPaneHidden} loginUser={loginUser} options={this.data.ratioData} tab="ratio" cube={this.data.cubeName} updatedTime={this.data.ratioData.updatedTime} chartInfo={chartInfo} onChangeChartType={this.changeChartType.bind(this, 0)} />
                        </Tab>}                    
                    </Tabs>
                </OverlayTrigger>
                </React.Fragment>
            ;
        }

        return <>{this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}</>;
    }

    graphIt = () => {
        handleClick("graphIt", "pivotHeadPane", "analysis");
        this.setState({isGraphLoading: true, isGraphCanceled:false});      
        this.data.headPanePivotServerRequest["BypassCache"] = this.state.bypassCache;
        this.data.headPanePivotServerRequest["IsHourly"] = this.props.isHourly;
        this.addRequestToUrl({...this.data.headPanePivotServerRequest, ...this.data.bodyPanePivotServerRequest});

        for(let key in this.data) {
            if(this.data[key] && this.data[key]["upToDate"]) {
                this.data[key]["upToDate"] = false;
            }
        }

        // Combine the headpane pivot request obj and the bodypane pivot request obj
        if (this.props.isPivotDynamicallyGenerated || this.props.isEvo)
        {
            var request = this.data.headPanePivotServerRequest;
            request['filters'] = this.data.bodyPanePivotServerRequest;
            this.dataQuery(request);
        }
        else
        {
            this.dataQuery({...this.data.headPanePivotServerRequest, ...this.data.bodyPanePivotServerRequest});
        }
    }

    cancelGraphIt = () => {        
        handleClick("Cancel graphing", "chartingPane", "analysis");
        this.setState({isGraphLoading: false, showChangeTabTip: false, isGraphCanceled:true});
        if (this.dataQuerySource) {
            this.dataQuerySource.cancel()
            this.dataQuerySource = axios.CancelToken.source()
        }
        this.clearEmptySeries(this.state.tab);
        this.data.dateRangeChange = true;
    }

    //#region Pivot row functions
    // change rollup checkbox
    rollupChange = (rollUpServerName, checked) => {
        if (rollUpServerName) {
            this.changeBodyPanePivot(rollUpServerName, checked ? "true" : "false");
        }
    }

    // change not checkbox
    notChange = (serverName, checked) => {
        this.data.notFlag[serverName] = checked;
        let stringValue = this.getPivotStringValue(serverName);
        this.changeBodyPanePivot(serverName, stringValue);
    }

    // change selected values
    onSelectChange = (serverName, inputValue) => {
        if (inputValue.length !== 0) {
            let selected = inputValue.map(e => '"' + e.value + '"').join("|");
            this.data.selected[serverName] = selected;
        } else {
            this.data.selected[serverName] = null;
        }
        let stringValue = this.getPivotStringValue(serverName);
        this.changeBodyPanePivot(serverName, stringValue);
    }
    
    // get string value for certain pivot
    getPivotStringValue = serverName => {
        let selected = this.data.selected[serverName];
        if (selected == null) {
            return null;
        }

        let ret = "";
        if (this.data.notFlag[serverName]) {
            ret = "!";
        }
        ret = ret + selected;
        return ret;
    }
    
    getDefaultValues = (pivot) => {
        // memorize
        if (this.data.defaultValues[pivot.serverName]) {
            return this.data.defaultValues[pivot.serverName];
        }

        // parse default values
        let defaultSelectedValues = [];
        let defaultRollUp = true;
        let defaultNotFlag = false;

        // rollUp default value
        if (pivot.rollUp) {
            let rollUpStr = this.state.urlDefaultParams[pivot.rollUp.serverName];
            if (rollUpStr) {
                defaultRollUp = rollUpStr === 'true';
            } else {
                defaultRollUp = pivot.rollUp.defaultVal;
            }
        }

        // selected pivots default value
        let pivotStr = this.state.urlDefaultParams[pivot.serverName];
        if (pivotStr === '' || pivotStr) {
            // use default values from url
            if (pivotStr.length > 1) {
                if (pivotStr[0] === '!') {
                    defaultNotFlag = true;
                    pivotStr = pivotStr.substring(1);
                }
            }

            // handle values like "a|b|c"|"d"
            // step 1. get index of ", in the example above, we get [0,6,8,10]
            let delimiterIndex = [];
            for (let i = 0; i < pivotStr.length; i++) {
                if (pivotStr[i] === '"') {
                    delimiterIndex.push(i);
                }
            }

            // step 2. iterate the index array in pairs
            // In the example, we get (0,6), (8,10). use these pairs to extract value 'a|b|c' and 'd'
            for (let i = 0; 2 * i + 1 < delimiterIndex.length; i++) {
                let start = delimiterIndex[2 * i];
                let end = delimiterIndex[2 * i + 1];
                let value = pivotStr.substr(start + 1, end - start - 1);
                defaultSelectedValues.push({ "value": value, "label": (!value)?"(blank)":value });
            }
        } else {
            // use default values from pivot settings
            defaultSelectedValues = pivot.defaultVal.map(e => {
                return { "value": e, "label": (!e)?"(blank)":e }
            });
        }

        let defaultValues = { defaultSelectedValues, defaultNotFlag, defaultRollUp };
        this.data.defaultValues[pivot.serverName] = defaultValues;

        // save default values to request object in parent component
        this.onSelectChange(pivot.serverName, defaultSelectedValues);
        this.notChange(pivot.serverName, defaultNotFlag);
        if (pivot.rollUp) {
            this.rollupChange(pivot.rollUp.serverName, defaultRollUp);
        }
        return defaultValues;
    }
    //#endregion

    //#region Cosmic
    getPivotsDynamicallyBasedOnDateRange = (fromDate, toDate) => {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true});
            // when refresh the pivots for cosmic, discard the old cosmic pivot mapping
            if (this.data.mapping && this.data.mapping.groupsList && this.data.mapping.groupsList.length > 1)
            {
                this.data.mapping.groupsList.pop();
            }

            this.data.bodyPanePivotServerRequest = {};
            if (fromDate && toDate)
            {
                const { evoServiceId, evoLogId, isEvo } = this.props;
                const { evoServiceName, cosmicServiceName } = this.data;
                let from = formatUTCDateAsDashString(convertStringToUTCDate(fromDate));
                let to = formatUTCDateAsDashString(convertStringToUTCDate(toDate));

                let pivotPromise = isEvo ? EvoDataService.evoPivots(evoServiceId, evoLogId, from, to) : EvoDataService.pivots({serviceName: cosmicServiceName, startDate: fromDate, endDate: toDate});
                pivotPromise.then(res => {
                    if (res.data.pivotGroup)
                    {
                        var bodypaneServerRequest = {};
                        res.data.pivotGroup.pivots.map(pivot => {
                            if (pivot.serverName)
                            {
                                bodypaneServerRequest[pivot.serverName] = null;
                                this.data.serverNameToUrlMap[pivot.serverName] = pivot.paramName;
                                this.data.serverNameToDefaultValue[pivot.serverName] = null;
                            }

                            if (pivot.rollUp)
                            {
                                bodypaneServerRequest[pivot.rollUp.serverName] = "true";
                                this.data.serverNameToUrlMap[pivot.rollUp.serverName] = pivot.rollUp.paramName;
                                this.data.serverNameToDefaultValue[pivot.rollUp.serverName] = pivot.rollUp.defaultVal.toString();
                            }

                            return pivot;
                        })

                        this.data.bodyPanePivotServerRequest = bodypaneServerRequest;
                        this.data.mapping.groupsList.push(res.data.pivotGroup);
                        this.setState({pivots: res.data.pivots, error: '', isDynamicGeneratedPivotNull: false})
                    }
                    else 
                    {
                        let nearestAvailableDatePromise = isEvo ? EvoDataService.getEvoNearestAvailableDate(evoServiceId, evoLogId, from, to) : EvoDataService.nearestAvailableDate({serviceName: cosmicServiceName, startDate: fromDate, endDate: toDate});
                        nearestAvailableDatePromise
                            .then(res => {
                                this.setState({error: `${isEvo ? evoServiceName : cosmicServiceName} does not have any data during this date range. Nearest available date ${res.data}`, isDynamicGeneratedPivotNull: true});
                            }).catch((err) => {
                                console.error(getErrorMessage(err, true));
                                this.handleError(err, "nearestAvailableDate");
                            });
                    }

                    this.setState({isLoading: false})
                    resolve("Success")
                })
                .catch((err) => {
                    console.error(getErrorMessage(err, true));
                    this.handleError(err, "pivots");
                    reject("failed");
                })
            }
            else
            {
                this.setState({isLoading: false})
                resolve("Success")
            }
        })
    }
    //#endregion

    componentDidMount() {
        if(this.data.cubeName)
        {
            document.title = getPageTitle(titles.CUBES, this.props.cubeDisplayName);
            this.setState({isLoading: true});
            this.getLegendSupport();

            DataService.get(this.data.cubeName + "/isPrefixSupportedFlag").then(res=>{
                this.setState({supportPrefix: res.data});
            })

            DataService.post(this.data.cubeName + "/mapping", {})
                .then(res => {

                    // get pivots
                    DataService.get(this.data.cubeName + "/pivots")
                    .then(res => {
                        this.setState({ isLoading: false, pivots: res.data.Value });
                        if (config.enableEventTracking) {
                            window.localStorage.setItem("loadTime", new Date().getTime() - this.data.startTime);
                        }
                    }).catch((err) => {
                        if (config.enableEventTracking) {
                            window.localStorage.setItem("loadTime", new Date().getTime() - this.data.startTime);
                        }
                        this.handleError(err, "pivots");
                    })

                    // get mapping data and do some transformation
                    let mapping = res.data;
                    this.data.mapping = mapping;

                    // init server request and parse url
                    this.initializeServerRequest();
                    let {autoGraph, metrics, urlDefaultParams, tab, timezone, bypassCache, chartType} = this.parseUrlToState();
                    let params = new URLSearchParams(this.props.location.search);
                    params.set('graphIt', false);
                    params.set('tab', tab);
                    this.props.history.push(window.location.pathname + "?" + params.toString());
                    this.setState({urlDefaultParams, metrics, tab, timezone, bypassCache, chartType}, ()=>{
                        if(autoGraph) {
                            this.graphIt();
                        }
                    })
                }).catch((err) => this.handleError(err, "mapping"));
        }
        else if (this.props.isEvo)
        {
            this.setState({isLoading: true});
            document.title = getPageTitle(titles.EVO, this.data.evoServiceName)
            
            EvoDataService.getEvoServiceConfiguration()
            .then(res => {
                this.data.mapping = {
                    groupsList: res.data.groupsList,
                    tabsList: res.data.tabsList.map(t => this.allowedTabs[t])
                };
                this.initializeServerRequest();
                this.data.headPanePivotServerRequest['ServiceId'] = this.props.evoServiceId;
                this.data.headPanePivotServerRequest['LogId'] = this.props.evoLogId;
                let {autoGraph, metrics, urlDefaultParams, tab} = this.parseUrlToState();
                this.data.lastFromDate = urlDefaultParams['StartDate'];
                this.data.lastToDate = urlDefaultParams['EndDate'];

                this.getPivotsDynamicallyBasedOnDateRange(urlDefaultParams['StartDate'], urlDefaultParams['EndDate']).then(res => {
                    let params = new URLSearchParams(this.props.location.search);
                    params.set('graphIt', false);
                    params.set('tab', tab);
                    this.props.history.push(window.location.pathname + "?" + params.toString());
                    this.setState({urlDefaultParams, metrics, tab}, ()=>{
                        if(autoGraph) {
                            this.graphIt();
                        }
                    }) 
                });
            })

            window.localStorage.setItem("loadTime", new Date().getTime() - this.data.startTime);
            this.setState({isLoading: false});
        }
        else if (this.props.isPivotDynamicallyGenerated)
        {
            this.setState({isLoading: true});
            document.title = getPageTitle(titles.COSMIC, this.data.cosmicServiceName)

            EvoDataService.getMappings(this.data.cosmicServiceName)
                .then(
                    res => {
                        this.data.mapping = res.data;
                        this.initializeServerRequest();
                        this.data.headPanePivotServerRequest['serviceName'] = this.data.cosmicServiceName;
                        let {autoGraph, metrics, urlDefaultParams, tab} = this.parseUrlToState();
                        this.data.lastFromDate = urlDefaultParams['StartDate'];
                        this.data.lastToDate = urlDefaultParams['EndDate'];

                        this.getPivotsDynamicallyBasedOnDateRange(urlDefaultParams['StartDate'], urlDefaultParams['EndDate']).then(res => {
                            let params = new URLSearchParams(this.props.location.search);
                            params.set('graphIt', false);
                            params.set('tab', tab);
                            this.props.history.push(window.location.pathname + "?" + params.toString());
                            this.setState({urlDefaultParams, metrics, tab}, ()=>{
                                if(autoGraph) {
                                    this.graphIt();
                                }
                            }) 
                        })                   
                    });
            window.localStorage.setItem("loadTime", new Date().getTime() - this.data.startTime);
            this.setState({isLoading: false});
        }
    }

    getSnapshotBeforeUpdate() {
        this.data.renderStartTime = new Date().getTime();
        //getSnapshotBeforeUpdate() should have a return value 
        return null;
    }


    componentDidUpdate() {
        this.data.renderEndTime = new Date().getTime();
    }

    getMetricsValueList(data) {
        let index = 0;
        let metricsValueList = [];
        for (; index < data.length; index++) {
            const series = data[index];
            metricsValueList.push({
                value: series.Name,
                label: series.Name
            });
        }
        return metricsValueList
    }

    render()
    {
        const { isHourly, isEvo, isPivotDynamicallyGenerated } = this.props;
        if (isEvo)
        {
            this.data.evoServiceName = getNameFromEvoUrl(this.props.location.pathname);
        }
        else if (isPivotDynamicallyGenerated)
        {
            this.data.cosmicServiceName = getNameFromCosmicUrl(this.props.location.pathname);
        }
        else
        {
            this.data.cubeName = getNameFromAnalysisUrl(this.props.location.pathname);
        }

        let isLoading = this.state.isLoading || this.state.isGraphLoading;
        let url = window.location.href;
        let isProgressLoad = this.getIsProgressiveLoading(this.state.tab);
        return (
            <Container fluid noGutters style={{padding : "0px"}}>
                <GetRawLogs cubeName={this.data.cubeName}
                            isHourly={isHourly}
                            hideGetRawLogs={this.handleCloseGetRawLogs} 
                            showGetRawLogs={this.state.showGetRawLogs}
                            logList={this.data.mapping.extraLogsList}
                            defaultDate={this.data.headPanePivotServerRequest.EndDate}
                            request={
                                this.state.currentDisplayedRequest!=null?
                                this.state.currentDisplayedRequest:
                                {...this.data.headPanePivotServerRequest, ...this.data.bodyPanePivotServerRequest}}
                            url={url}
                />
                <InsightsModal
                    cubeName={this.data.cubeName}
                    hasError={this.state.hasMultipleTrendLines}
                    hideInsightsModal={this.handleCloseInsightModal}
                    showInsightsModal={this.state.showInsightsModal}
                    tab={this.state.tab}
                    checkpointList={this.getMetricsValueList(this.data.checkpointChartData.serverData)}
                    metricsList={this.getMetricsValueList(this.data.metricChartDataLatency.serverData)}
                ></InsightsModal>
                <Row noGutters>
                    {!this.state.isChartingPaneHidden ?
                        <Col className="open chartingPaneContainer">
                            {this.state.isLoading && <LoadingOverlay/>}
                        <PivotHeadPane
                            isHourly={isHourly}
                            urlDefaultParams={this.state.urlDefaultParams}
                            mapping={this.data.mapping}
                            changeHeadPanePivot={this.changeHeadPanePivot}
                            changeChartingPaneDateRange={this.changeChartingPaneDateRange}
                            changeAutoGraph={this.changeAutoGraph}
                            changeChartingPaneTimezone={this.changeChartingPaneTimezone}
                            hideChartingPane={() => {this.setState({isChartingPaneHidden: true})}}
                            isLoading={isLoading}
                            isGraphing={this.state.isGraphLoading}
                            isProgressLoad={isProgressLoad}
                            progress={this.data.progressLoading.progress}
                            progressTask={this.data.progressLoading.task}
                            updateProgressTimeInterval={this.updateProgressTimeInterval}
                            cubeName={this.data.cubeName}
                            updatedTime={this.state.updatedTime}
                            graphIt={this.graphIt}
                            cancelGraphIt={this.cancelGraphIt}
                            handleGetRawLogsButton={this.handleGetRawLogsButton}
                            handleInsightsButton={this.handleInsightsButton}
                            pivots={this.state.pivots}
                            supportPrefix={this.state.supportPrefix}
                            onSelectChange={this.onSelectChange}
                            getDefaultValues={this.getDefaultValues}
                            addPivots={this.addPivots}
                            tab={this.state.tab}
                            isRawLogsEnabled={!isPivotDynamicallyGenerated && this.data.mapping.extraLogsList}
                            isInsightEnabled={!isPivotDynamicallyGenerated && !isHourly}
                            isDynamicGeneratedPivotNull={this.state.isDynamicGeneratedPivotNull}
                            isGraphCanceled={this.isGraphCanceled}
                        ></PivotHeadPane>
                        <PivotBodyPane
                            cubeName={this.data.cubeName}
                            urlDefaultParams={this.state.urlDefaultParams}
                            mapping={this.data.mapping}
                            pivots={this.state.pivots}
                            supportPrefix={this.state.supportPrefix}
                            handleClick={handleClick}
                            onSelectChange={this.onSelectChange}
                            notChange={this.notChange}
                            rollupChange={this.rollupChange}
                            getDefaultValues={this.getDefaultValues}
                            addPivots={this.addPivots}
                        >
                        </PivotBodyPane>
                    </Col> :
                        <Col className="chartingPaneContainer" style={{ maxWidth: "3em"}}>
                            <Button style={{ height: '2em'}} variant="light" onClick={() => { this.setState({ isChartingPaneHidden: false }) }} disabled={this.state.isLoading}>
                                <FontAwesomeIcon style={{ color: 'grey' }} icon={faCaretRight} />
                    </Button>
                        </Col>
                    }
                    <Col style={{ maxWidth: this.state.isChartingPaneHidden ? "100%" : "72%", maxHeight: "92.8vh", overflowY: 'hidden' }} >
                        {this.state.isGraphLoading && ( !isProgressLoad || this.data.progressLoading.progress.length === 0) && <div className="overlay" role="status">
                            <div aria-hidden="true"><CenteredLoadingGif /></div><span class="sr-only">Loading </span>
                        </div>}
                        {this.state.isGraphCanceled && <div role="status">
                            <span class="sr-only">Graphing canceled </span>
                        </div>}                       
                        {this.renderTabList()}
                    </Col>
                </Row>

            </Container> 
        )
    }
}

export default withRouter(ChartingPane);