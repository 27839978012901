import React, { useEffect, useState, useRef } from 'react';
import './EvoServiceRegistration.css';
import ServiceList from './ServiceList';
import { Row, Button, Alert, Container, Jumbotron } from 'react-bootstrap';
import EvoDataService from '../../Dataservices/EvoDataService';
import Service from './Service';
import ServiceLog from './ServiceLog';
import ServiceLogDefinition from './ServiceLogDefinition';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faBan } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from './DeleteModal';
import { checkListContainsCurrentUser } from '../../Dataservices/AuthUtils';
import { formatUTCDateAsString, convertStringToUTCDate } from '../../Functions/DateTimeHelper';
import { getErrorMessage } from "../../Functions/CommonHelper";
import { getOtherPivotNameAlias } from './EvoRegistrationHelper';

function EvoServiceRegistration(props) {
    const { loginUser, isAdmin } = props;

    const [dataSourceList, setDataSourceList] = useState([]);
    const [parserList, setParserList] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});

    const [serviceList, setServiceList] = useState([]);
    const [isServiceListLoading, setIsServiceListLoading] = useState(true);
    const [selectedServiceIndex, setSelectedServiceIndex] = useState(-1);
    const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
    const [showDeprecateServiceModal, setShowDeprecateServiceModal] = React.useState(false);
    const [serviceSectionStatus, setServiceSectionStatus] = useState(['', '']);

    const [serviceLogList, setServiceLogList] = useState([]);
    const [isServiceLogLoading, setIsServiceLogLoading] = useState(false);
    const [selectedServiceLogIndex, setSelectedServiceLogIndex] = useState(-1);
    const [serviceLogSectionStatus, setServiceLogSectionStatus] = useState(['', '']);

    const [serviceLogDefinitionList, setServiceLogDefinitionList] = useState([]);
    const [isServiceLogDefinitionLoading, setIsServiceLogDefinitionLoading] = useState(false);  
    const [selectedServiceLogDefinitionIndex, setSelectedServiceLogDefinitionIndex] = useState(-1);
    const [serviceLogDefinitionToUpdate, setServiceLogDefinitionToUpdate] = useState(null);
    const [serviceLogDefinitionSectionStatus, setServiceLogDefinitionSectionStatus] = useState(['', '']);
    const [selectedVersionValue, setSelectedVersionValue] = useState('');
    const [versionDropdownList, setVersionDropdownList] = useState([]);

    const [error, setError] = useState('');
    const [initDefinitionMessage, setInitDefinitionMessage] = useState('');

    const [isEditable, setIsEditable] = useState(false);

    const basicColumns = useRef([
        { dataKey: 'serviceLogColumns.dateColumn', label: 'Date Column Name', isRequired: true },
        { dataKey: 'serviceLogColumns.traceIdColumn', label: 'TraceId Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.spanIdColumn', label: 'SpanId Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.ringColumn', label: 'Ring Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.buildColumn', label: 'Build Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.regionColumn', label: 'Region Column Name', isRequired: false }
      ]);

    const advancedColumns = useRef([
        { dataKey: 'serviceLogColumns.partitionColumn', label: 'Partition Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.latencyColumn', label: 'Latency Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.sampleCountColumn', label: 'Sample Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.tagColumn', label: 'Tag Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.checkpointColumn', label: 'Checkpoint Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.measureColumn', label: 'Measure Column Name', isRequired: false },
        { dataKey: 'serviceLogColumns.appNameColumn', label: 'App Name Column Name', isRequired: false }
    ]);

    const createEmptyLogDefinition = () => {
        let parserId = 0;
        let filterClause = "";
        let partitionColumn = "";
        let logName = serviceLogList[selectedServiceLogIndex]?.logName;
        if (defaultValues[logName]) {
            parserId = defaultValues[logName].defaultParserId;
            filterClause = defaultValues[logName].defaultFilterClause || "";
            partitionColumn = defaultValues[logName].defaultPartitionColumn || "";
        }

        return {
            definitionId: -1,
            serviceLogId: serviceLogList[selectedServiceLogIndex]?.serviceLogId,
            version: serviceLogDefinitionList[0]?.version + 1 || 1,
            dataSourceId: 0,
            dataSourceAccessInfo: '',
            parserId: parserId,
            serviceLogColumns: {
                dateColumn: '',
                traceIdColumn: '',
                spanIdColumn: '',
                ringColumn: '',
                buildColumn: '',
                regionColumn: '',
                otherPivotColumns: [],
                latencyColumn: '',
                sampleCountColumn: '',
                tagColumn: '',
                checkpointColumn: '',
                measureColumn: '',
                partitionColumn: partitionColumn,
                appNameColumn: ''
            },
            filterClause: filterClause,
            joinSide:{
                value: 'NotApplicable'
            },
            logSizeInGB: 0,
            createdOn: getFormattedUTCStrOfNow()
        };
    };

    const getFormattedUTCStrOfNow = () => {
        return formatUTCDateAsString(new Date());
    }

    const getVersionDropdownOption = (version, createdOn) => {
        return 'Version ' + version + ' - Created on ' + formatUTCDateAsString(convertStringToUTCDate(createdOn));
    };

    useEffect(() => {
        setIsServiceListLoading(true);
        EvoDataService.getDataSources()
            .then((dataSources) => {
                setDataSourceList(dataSources.data);
            })
            .catch((error) => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });

        EvoDataService.getParsers()
            .then((parsers) => {
                setParserList(parsers.data);
            })
            .catch((error) => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });

        EvoDataService.getLogDefaultValues()
            .then((defaultValues) => {
                setDefaultValues(defaultValues.data.reduce((acc, curr) => {
                    acc[curr.logName] = {
                        defaultParserId: curr.defaultParserId,
                        defaultFilterClause: curr.defaultFilterClause,
                        defaultPartitionColumn: curr.defaultPartitionColumn,
                    };
                    return acc;
                }, {}));
            })
            .catch((error) => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });

        const createUserObjectFromStr = (user) => {
            let parts = user.split(':');
            return {
                Id: 0,
                GUID: parts[0],
                Type: parts[1]
            }
        };

        const populateService = (services, status) => {
            return services.map(s => {
                let oncall = [];
                for (let i = 0; i < s.onCallUsers.length; i++) {
                    oncall.push(createUserObjectFromStr(s.onCallUsers[i]))
                }

                let admin = [];
                for (let i = 0; i < s.adminUsers.length; i++) {
                    admin.push(createUserObjectFromStr(s.adminUsers[i]))
                }

                return {
                    serviceId: s.serviceId, 
                    serviceName: s.serviceName, 
                    status: status, 
                    onCallUsers: oncall, 
                    adminUsers: admin
                }; 
            })
        }

        EvoDataService.getActiveServices()
            .then((active) => {
                EvoDataService.getDeprecatedServices()
                    .then((deprecated) => {
                        setServiceList([...populateService(active.data, 'Active'), ...populateService(deprecated.data, 'Deprecated')]);
                        setIsServiceListLoading(false);
                    })
                    .catch((error) => {
                        console.error(getErrorMessage(error, true));
                        setError(getErrorMessage(error));
                        setIsServiceListLoading(false);
                    });
            })
            .catch((error) => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setIsServiceListLoading(false);
            });

        
    }, []);

    useEffect(() => {
        setError('');
        setInitDefinitionMessage('');
        setServiceSectionStatus(['', '']);
        setServiceLogSectionStatus(['', '']);
        setServiceLogDefinitionSectionStatus(['', '']);
    }, [selectedServiceIndex]);

    useEffect(() => {
        setServiceLogSectionStatus(['', '']);
        setServiceLogDefinitionSectionStatus(['', '']);
    }, [selectedServiceLogIndex]);

    useEffect(() => {
        setServiceLogDefinitionSectionStatus(['', '']);
    }, [selectedServiceLogDefinitionIndex]);

    useEffect(() => {
        if (selectedServiceLogDefinitionIndex === -1) {
            setServiceLogDefinitionToUpdate(null);
        }
        else if (selectedServiceLogDefinitionIndex !== -1 && serviceLogDefinitionList.length !== 0) {
            setServiceLogDefinitionToUpdate({...serviceLogDefinitionList[selectedServiceLogDefinitionIndex]});
        }
    }, [selectedServiceLogDefinitionIndex, serviceLogDefinitionList]);

    const onSelectService = (serviceIndex) => {
        setSelectedServiceIndex(serviceIndex);
        onSelectServiceLog(-1);

        if (serviceIndex === -1) return;

        if (serviceList[serviceIndex].serviceId === -1) {
            return;
        }

        setIsServiceLogLoading(true);
        let api = serviceList[serviceIndex].status === 'Active' ? EvoDataService.getActiveServiceLogsByServiceId : EvoDataService.getDeprecatedServiceLogsByServiceId;
        api(serviceList[serviceIndex].serviceId)
        .then((serviceLogs) => {
            setServiceLogList(serviceLogs.data);
            if (serviceLogs.data.length > 0) {
                onSelectServiceLog(0, serviceLogs.data);
            } else {
                onSelectServiceLog(-1);
            }
            setIsServiceLogLoading(false);
        })
        .catch((error) => {
            console.error(getErrorMessage(error, true));
            setError(getErrorMessage(error));
            setIsServiceLogLoading(false);
        });

        if (isAdmin) {
            setIsEditable(true);
        } else {
            checkListContainsCurrentUser(serviceList[serviceIndex].adminUsers, loginUser.userName, (contains) => setIsEditable(contains), loginUser.id);
        }
    };

    const onSelectServiceLog = (logIndex, logList) => {
        setSelectedServiceLogIndex(logIndex);
        onSelectServiceLogDefinition(-1);

        if (logIndex === -1) {
            setServiceLogList([]);
            return;
        }
        
        const logId = logList ? logList[logIndex].serviceLogId : serviceLogList[logIndex].serviceLogId;
        if (logId === -1) {
            // This is a newly added log which hasn't been saved to database yet.
            return;
        }

        setIsServiceLogDefinitionLoading(true);
        EvoDataService.getServiceLogDefinitionByLogId(logId)
        .then((serviceLogDefinition) => {
            setServiceLogDefinitionList(serviceLogDefinition.data.map((d) => {
                // Convert the data to the format that the UI can handle. In case backend changes the data structure, we can easily change it here.
                return {
                    definitionId: d.definitionId,
                    serviceLogId: d.serviceLogId,
                    version: d.version,
                    dataSourceId: d.dataSourceId,
                    dataSourceAccessInfo: d.dataSourceAccessInfo,
                    parserId: d.parserId,
                    serviceLogColumns: {
                        dateColumn: d.serviceLogColumns.dateColumn,
                        traceIdColumn: d.serviceLogColumns.traceIdColumn,
                        spanIdColumn: d.serviceLogColumns.spanIdColumn,
                        ringColumn: d.serviceLogColumns.ringColumn,
                        buildColumn: d.serviceLogColumns.buildColumn,
                        regionColumn: d.serviceLogColumns.regionColumn,
                        otherPivotColumns: d.serviceLogColumns.otherPivotColumns.filter((c) => c), // filter away the empty other pivot columns
                        latencyColumn: d.serviceLogColumns.latencyColumn,
                        sampleCountColumn: d.serviceLogColumns.sampleCountColumn,
                        tagColumn: d.serviceLogColumns.tagColumn,
                        checkpointColumn: d.serviceLogColumns.checkpointColumn,
                        measureColumn: d.serviceLogColumns.measureColumn,
                        partitionColumn: d.serviceLogColumns.partitionColumn,
                        appNameColumn: d.serviceLogColumns.appNameColumn
                    },
                    filterClause: d.filterClause,
                    joinSide:{
                        value: d.joinSide.Value
                    },
                    logSizeInGB: d.logSizeInGB,
                    createdOn: d.createdOn
                }
            }));
            setVersionDropdownList(serviceLogDefinition.data.map((definition) => getVersionDropdownOption(definition.version, definition.createdOn)));
            if (serviceLogDefinition.data.length > 0) {
                onSelectServiceLogDefinition(0);
                setSelectedVersionValue(getVersionDropdownOption(serviceLogDefinition.data[0].version, serviceLogDefinition.data[0].createdOn));
            } else {
                onSelectServiceLogDefinition(-1);
            }
            setIsServiceLogDefinitionLoading(false);
        })
        .catch((error) => {
            console.error(getErrorMessage(error, true));
            setServiceLogSectionStatus([`Fail to get the log definitions for the selected log. Error: ${getErrorMessage(error)}`, 'error']);
            setIsServiceLogDefinitionLoading(false);
        });
    };

    const onSelectServiceLogDefinition = (index, isVersionSelectionChange) => {
        if (index === selectedServiceLogDefinitionIndex && isVersionSelectionChange) {
            return;
        }

        setSelectedServiceLogDefinitionIndex(index);

        if (index === -1) {
            setServiceLogDefinitionList([]);
            setServiceLogDefinitionToUpdate(null);
            setSelectedVersionValue('');
            setVersionDropdownList([]);
        } else if (serviceLogDefinitionList && serviceLogDefinitionList[index]) {
            setSelectedVersionValue(getVersionDropdownOption(serviceLogDefinitionList[index].version, serviceLogDefinitionList[index].createdOn));
        } else {
            setSelectedVersionValue('');
        }
    }

    const onServiceChange = (newVal, dataKey) => {
        let serviceToUpdate = serviceList[selectedServiceIndex];
        serviceToUpdate[dataKey] = newVal;
        serviceList.splice(selectedServiceIndex, 1, serviceToUpdate)
        setServiceList([...serviceList]);
        setServiceSectionStatus(['Service is edited. Please click Save to save the changes.', 'info']);
    };

    const onServiceLogChange = (newVal, dataKey, index) => {
        let serviceLogToUpdate = serviceLogList[selectedServiceLogIndex];

        if (dataKey === 'SelectLog') {
            onSelectServiceLog(index);
            return;
        }

        if (dataKey && (index === undefined || index === selectedServiceLogIndex) && serviceLogToUpdate[dataKey] !== newVal) {
            serviceLogToUpdate[dataKey] = newVal;
            serviceLogList.splice(selectedServiceLogIndex, 1, serviceLogToUpdate);
            setServiceLogList([...serviceLogList]);

            setServiceLogSectionStatus(['Log edited. Please click Save to save the changes.', 'info']);
        }
    };

    const onServiceLogDefinitionChange = (changedKey, newVal) => {
        if (changedKey.startsWith('serviceLogColumns')) {
            const nestedKeys = changedKey.split('.');
            serviceLogDefinitionToUpdate[nestedKeys[0]][nestedKeys[1]] = newVal;
        } else if (changedKey === 'joinSide') {
            serviceLogDefinitionToUpdate.joinSide.value = newVal;
        } else {
            serviceLogDefinitionToUpdate[changedKey] = newVal;
        }
        
        setServiceLogDefinitionToUpdate({...serviceLogDefinitionToUpdate});
    };

    const onAddService = () => {
        const defaultUser = {
            Id: 0,
            GUID: loginUser.id,
            Type: 'Person',
            DisplayName: loginUser.displayName,
            EmailAddress: loginUser.userName
        };

        setServiceList([...serviceList, {
            serviceId: -1, 
            serviceName: '', 
            status: 'Active', 
            onCallUsers: [], 
            adminUsers: [defaultUser]
        }]);
        setSelectedServiceIndex(serviceList.length);
        setServiceLogList([]);
        setSelectedServiceLogIndex(-1);
        setServiceLogDefinitionList([]);
        setSelectedServiceLogDefinitionIndex(-1);
        setIsEditable(true);
    };

    const onDeleteService = () => {
        toggleDeleteServiceModal();
        if (selectedServiceIndex !== -1 && serviceList.length > 0 && serviceList[selectedServiceIndex]) {
            if (serviceList[selectedServiceIndex].serviceId === -1) {
                serviceList.splice(selectedServiceIndex, 1);
                setServiceList([...serviceList]);
                setSelectedServiceIndex(-1);
            } else {
                EvoDataService.deleteService(serviceList[selectedServiceIndex].serviceId)
                .then(() => {
                    serviceList.splice(selectedServiceIndex, 1);
                    setServiceList([...serviceList]);
                    setSelectedServiceIndex(-1);
                })
                .catch((error) => {
                    console.error(getErrorMessage(error, true));
                    setError('Failed to delete Service. Error: ' + getErrorMessage(error));
                });
            }
        }
    }

    const onDeprecateService = () => {
        toggleDeprecateServiceModal();
        if (selectedServiceIndex !== -1 && serviceList.length > 0 && serviceList[selectedServiceIndex]) {
            if (serviceList[selectedServiceIndex].serviceId === -1) {
                return;
            } else {
                EvoDataService.deprecateService(serviceList[selectedServiceIndex].serviceId)
                .then(() => {
                    serviceList[selectedServiceIndex].status = 'Deprecated';
                    setServiceList([...serviceList]);
                })
                .catch((error) => {
                    console.error(getErrorMessage(error, true));
                    setError('Failed to deprecate Service. Error: ' + getErrorMessage(error));
                });
            }
        }
    }

    const toggleDeleteServiceModal = () => {
        setShowDeleteServiceModal(!showDeleteServiceModal);
    };

    const toggleDeprecateServiceModal = () => {
        setShowDeprecateServiceModal(!showDeprecateServiceModal);
    };

    const onAddServiceLog = (logName) => {
        setSelectedServiceLogIndex(serviceLogList.length);
        setServiceLogList([...serviceLogList, {
            serviceLogId: -1, 
            logName: logName, 
            availableFrom: new Date(), 
            lookBackPeriodInDays: 7, 
            serviceId: serviceList[selectedServiceIndex].serviceId, 
            status: 'Active'}]);
        setServiceLogDefinitionList([]);
    };

    const onDeleteServiceLog = () => {
        if (selectedServiceIndex !== -1 && selectedServiceLogIndex !== -1 && serviceLogList.length > 0 && serviceLogList[selectedServiceLogIndex]) {
            if (serviceLogList[selectedServiceLogIndex].serviceLogId === -1) {
                serviceLogList.splice(selectedServiceLogIndex, 1);
                setServiceLogList([...serviceLogList]);
                if (serviceLogList.length > 0) {
                    onSelectServiceLog(0);
                } else {
                    onSelectServiceLog(-1);
                }
            } else {
                setServiceLogSectionStatus(['Deleting log...', 'info'])

                EvoDataService.deleteServiceLog(serviceLogList[selectedServiceLogIndex].serviceLogId)
                .then(() => {
                    serviceLogList.splice(selectedServiceLogIndex, 1);
                    setServiceLogList([...serviceLogList]);
                    if (serviceLogList.length > 0) {
                        onSelectServiceLog(0);
                    } else {
                        onSelectServiceLog(-1);
                    }
                    setServiceLogSectionStatus(['Log is deleted successfully.', 'success']);
                })
                .catch((error) => {
                    console.error(getErrorMessage(error, true));
                    setServiceLogSectionStatus([`Fail to delete service log. Error: ${getErrorMessage(error)}`, 'error']);
                });
            }
        }
    };

    const onCreateServiceLogDefinition = () => {
        if (serviceLogList.length === 0) {
            setInitDefinitionMessage('Please create a service log first.');
            return;
        }

        const empty = createEmptyLogDefinition();
        setServiceLogDefinitionToUpdate(empty);

        if (!(serviceLogDefinitionToUpdate && serviceLogDefinitionToUpdate.definitionId === -1)) {
            const newVersionOption = getVersionDropdownOption(empty.version, empty.createdOn);
            setSelectedVersionValue(newVersionOption);
            versionDropdownList.unshift(newVersionOption);
            setVersionDropdownList([...versionDropdownList]);
            serviceLogDefinitionList.unshift(empty);
            setServiceLogDefinitionList([...serviceLogDefinitionList]);
            setSelectedServiceLogDefinitionIndex(0);
        }
    };

    const onSaveService = () => {
        const serviceToUpdate = serviceList[selectedServiceIndex];

        if (!serviceToUpdate.serviceName) {
            setServiceSectionStatus(['Service name is required', 'error']);
            return;
        }

        if (serviceToUpdate.adminUsers.length === 0) {
            setServiceSectionStatus(['Admin users are required.', 'error']);
            return;
        }

        if (serviceToUpdate.onCallUsers.length === 0) {
            setServiceSectionStatus(['On call users are required.', 'error']);
            return;
        }

        setServiceSectionStatus(['Saving service...', 'info']);

        const req = {
            serviceName: serviceToUpdate.serviceName,
            onCallUsers: serviceToUpdate.onCallUsers.map(u => u.GUID + ':' + u.Type),
            adminUsers: serviceToUpdate.adminUsers.map(u => u.GUID + ':' + u.Type)
        };
        EvoDataService.addOrUpdateService(req, serviceToUpdate.serviceId)
        .then((res) => {
            const serviceId = res.data;
            if (serviceId !== serviceToUpdate.serviceId) {
                serviceToUpdate.serviceId = serviceId;
                serviceList.splice(selectedServiceIndex, 1, serviceToUpdate);
                setServiceList([...serviceList]);
            }
            setServiceSectionStatus(['Service is saved successfully', 'success']);
        })
        .catch((error) => {
            console.error(getErrorMessage(error, true));
            setServiceSectionStatus([`Service failed to save. Error: ${getErrorMessage(error)}`, 'error']);
        });
    }

    const onSaveServiceLog = () => {
        const serviceLogToUpdate = serviceLogList[selectedServiceLogIndex];

        if (!serviceLogToUpdate.logName) {
            setServiceLogSectionStatus(['Log name is required.', 'error']);
            return;
        }

        if (!serviceLogToUpdate.availableFrom) {
            setServiceLogSectionStatus(['Data Available From is required.', 'error']);
            return;
        }

        if (!serviceLogToUpdate.lookBackPeriodInDays) {
            setServiceLogSectionStatus(['Look Back Period is required.', 'error']);
            return;
        }

        const lookBackPeriodNumber = parseInt(serviceLogToUpdate.lookBackPeriodInDays);
        if (lookBackPeriodNumber < 1 || lookBackPeriodNumber > 7) {
            setServiceLogSectionStatus(['Look Back Period is out of range. Please enter a number between 1 and 7.', 'error']);
            return;
        }

        setServiceLogSectionStatus(['Saving log...', 'info']);

        EvoDataService.addOrUpdateServiceLog(serviceLogToUpdate, serviceLogToUpdate.serviceLogId)
        .then((res) => {
            const serviceLogId = res.data;
            if (serviceLogId !== serviceLogToUpdate.serviceLogId) {
                // This means the service log is newly added.
                serviceLogToUpdate.serviceLogId = serviceLogId;
                serviceLogList.splice(selectedServiceLogIndex, 1, serviceLogToUpdate);
                setServiceLogList([...serviceLogList]);
                setSelectedServiceLogDefinitionIndex(-1);
                setServiceLogDefinitionList([]);
            }
            setServiceLogSectionStatus(['Log is saved successfully.', 'success']);
        })
        .catch((error) => {
            console.error(getErrorMessage(error, true));
            setServiceLogSectionStatus([`Service failed to save. Error: ${getErrorMessage(error)}`, 'error']);
        });
    };

    const onSaveServiceLogDefinition = () => {
        // Validate the required fields
        if (!serviceLogDefinitionToUpdate.dataSourceId) {
            setServiceLogDefinitionSectionStatus(['Data Source is required.', 'error']);
            return;
        }

        if (!serviceLogDefinitionToUpdate.dataSourceAccessInfo) {
            const prompt = dataSourceList.find(d => d.name === serviceLogDefinitionToUpdate.dataSource)?.accessInfoDisplayName || 'Data Source Access Info';
            setServiceLogDefinitionSectionStatus([`${prompt} is required.`, 'error']);
            return;
        }

        if (!serviceLogDefinitionToUpdate.parserId) {
            setServiceLogDefinitionSectionStatus(['Parser is required.', 'error']);
            return;
        }

        let requiredValid = true;
        basicColumns.current.forEach((column) => {
            let dataKey = column.dataKey.split('.')[1];
            if (column.isRequired && !serviceLogDefinitionToUpdate.serviceLogColumns[dataKey]) {
                setServiceLogDefinitionSectionStatus([`${column.label} is required.`, 'error']);
                requiredValid = false;
                return;
            }
        });

        advancedColumns.current.forEach((column) => {
            let dataKey = column.dataKey.split('.')[1];
            if (column.isRequired && !serviceLogDefinitionToUpdate.serviceLogColumns[dataKey]) {
                setServiceLogDefinitionSectionStatus([`${column.label} is required.`, 'error']);
                requiredValid = false;
                return;
            }
        });

        if (!requiredValid) {
            return;
        }

        // Validate there are no duplicate column names
        const columns = serviceLogDefinitionToUpdate.serviceLogColumns;
        const uniqueRegisteredNames = {};
        const uniqueOtherPivotAlias = {};
        let valid = true;
        const duplicate = (registeredColumn, field1, field2) => {
            setServiceLogDefinitionSectionStatus([`Duplicate registered column: ${registeredColumn}. Please remove or change it from either ${field1} or ${field2}.`, 'error']);
            valid = false;
            return;
        };

        const findLabelForColumn = (column) => {
            if (column.startsWith('otherPivotColumns')) {
                return `line ${column.substring('otherPivotColumns'.length)} of Other Pivot Columns`;
            }
    
            const basicColumn = basicColumns.current.find(c => c.dataKey === 'serviceLogColumns.' + column);
            if (basicColumn) {
                return basicColumn.label;
            }
    
            const advancedColumn = advancedColumns.current.find(c => c.dataKey === 'serviceLogColumns.' + column);
            if (advancedColumn) {
                return advancedColumn.label;
            }
    
            return '';
        };

        Object.entries(columns).forEach(([columnCtrlName, registeredName]) => {
            if (columnCtrlName === 'otherPivotColumns') {
                columns[columnCtrlName].forEach((otherPivot, index) => {
                    let nameAlias = getOtherPivotNameAlias(otherPivot);
                    if (!nameAlias[0] || !nameAlias[1]) {
                        setServiceLogDefinitionSectionStatus([`Fields in Other Pivot Columns cannot be empty.`, 'error']);
                        valid = false;
                        return;
                    }

                    if (uniqueRegisteredNames[nameAlias[0]]) {
                        duplicate(nameAlias[0], `line ${index + 1} of Other Pivot Columns`, findLabelForColumn(uniqueRegisteredNames[nameAlias[0]]));
                    }

                    if (uniqueRegisteredNames[nameAlias[1]]) {
                        duplicate(nameAlias[1], `line ${index + 1} of Other Pivot Columns`, findLabelForColumn(uniqueRegisteredNames[nameAlias[1]]));
                    }

                    if (uniqueOtherPivotAlias[nameAlias[1]]) {
                        duplicate(nameAlias[1], `line ${index + 1} of Other Pivot Columns`, `line ${uniqueOtherPivotAlias[nameAlias[1]]} of Other Pivot Columns`);
                    }

                    uniqueRegisteredNames[nameAlias[0]] = columnCtrlName + (index + 1);
                    uniqueRegisteredNames[nameAlias[1]] = columnCtrlName + (index + 1);
                    uniqueOtherPivotAlias[nameAlias[1]] = index + 1;
                });
            }
            else if (uniqueRegisteredNames[registeredName]) {
                duplicate(registeredName, findLabelForColumn(columnCtrlName), findLabelForColumn(uniqueRegisteredNames[registeredName]));
            }
            else if (registeredName) {
                uniqueRegisteredNames[registeredName] = columnCtrlName;
            }
        });

        if (!valid) {
            return;
        }

        // Columns are valid. Save the definition.
        setServiceLogDefinitionSectionStatus(['Saving log definition...', 'info']);

        EvoDataService.addServiceLogDefinition(serviceLogDefinitionToUpdate)
        .then((res) => {
            serviceLogDefinitionToUpdate.createdOn = getFormattedUTCStrOfNow();

            if (serviceLogDefinitionToUpdate.definitionId === -1) {
                // The definition is a newly created version (User clicks 'Clear' button)
                serviceLogDefinitionToUpdate.definitionId = res.data;
            } else {
                // The definition is an existing version (User selected a history version and directly edited it)
                serviceLogDefinitionToUpdate.version = serviceLogDefinitionList[0] ? serviceLogDefinitionList[0].version + 1 : 1;
                setVersionDropdownList([getVersionDropdownOption(serviceLogDefinitionToUpdate.version, serviceLogDefinitionToUpdate.createdOn), ...versionDropdownList]);
                setSelectedVersionValue(getVersionDropdownOption(serviceLogDefinitionToUpdate.version, serviceLogDefinitionToUpdate.createdOn));
                serviceLogDefinitionList.unshift(serviceLogDefinitionToUpdate);
                setServiceLogDefinitionList([...serviceLogDefinitionList]);
                setSelectedServiceLogDefinitionIndex(0);
            }

            setServiceLogDefinitionSectionStatus([`Log definition is saved successfully as version ${serviceLogDefinitionToUpdate.version}.`, 'success']);
        
            if (!dataSourceList.find(d => d.name === serviceLogDefinitionToUpdate.dataSource)) {
                dataSourceList.push({name: serviceLogDefinitionToUpdate.dataSource});
                setDataSourceList([...dataSourceList]);
            }
    
            if (!parserList.find(p => p.name === serviceLogDefinitionToUpdate.parser)) {
                parserList.push({name: serviceLogDefinitionToUpdate.parser});
                setParserList([...parserList]);
            }
        })
        .catch((error) => {
            console.error(getErrorMessage(error, true));
            setServiceLogDefinitionSectionStatus([`Log definition failed to save. Error: ${getErrorMessage(error)}`, 'error']);
        });
    };

    return (
        <div id="evo-service-registration">
            <h1 className="page-title">PerfPanel Evo Service Registration</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="h-layout">
                <ServiceList 
                    serviceList={serviceList}
                    isLoading={isServiceListLoading}
                    onSelectService={onSelectService}
                    selectedServiceIdndex={selectedServiceIndex}
                    onAddService={onAddService}
                    />
                {selectedServiceIndex === -1 || !serviceList || serviceList.length === 0
                ? <Container fluid>
                    <Jumbotron className="empty-prompt-Jumbotron">
                        <h1>PerfPanel Evo Service Registration</h1>
                        <p>Select or create a service to start!</p>
                        <Button variant="primary" onClick={onAddService} tabIndex={1}><FontAwesomeIcon icon={faPlus} tabIndex={-1}/>&ensp;Create New Service</Button>
                    </Jumbotron>
                </Container>
                : <Container fluid>
                    {isEditable && <Row className="service-actions">
                        {serviceList[selectedServiceIndex]?.Id === -1
                        ?<Button variant="primary" onClick={toggleDeleteServiceModal}><FontAwesomeIcon icon={faBan}/>&ensp;Discard</Button>
                        : <React.Fragment>
                            {serviceList[selectedServiceIndex]?.status === 'Active'&& <Button variant="primary" onClick={toggleDeprecateServiceModal}><FontAwesomeIcon icon={faBan}/>&ensp;Deprecate</Button>}
                            <Button variant="primary" onClick={toggleDeleteServiceModal}><FontAwesomeIcon icon={faTrashAlt}/>&ensp;Delete</Button>
                        </React.Fragment>}
                    </Row>}
                    <Container>
                        <Row><h4 className="section-divider">STEP 1: CREATE SERVICE</h4></Row>
                        <Service 
                            serviceName={selectedServiceIndex === -1 || !serviceList ? '' : serviceList[selectedServiceIndex]?.serviceName}
                            onCallUserList={selectedServiceIndex === -1 || !serviceList ? [] : serviceList[selectedServiceIndex]?.onCallUsers}
                            adminUserList={selectedServiceIndex === -1 || !serviceList ? [] : serviceList[selectedServiceIndex]?.adminUsers}
                            onServiceChange={onServiceChange}
                            onSaveService={onSaveService}
                            sectionStatus={serviceSectionStatus}
                            isEditable={isEditable}
                            />

                        {selectedServiceIndex !== -1 && serviceList[selectedServiceIndex]?.serviceId !== -1 && <React.Fragment>
                            <Row><h4 className="section-divider">STEP 2: CREATE LOGS</h4></Row>
                            <ServiceLog
                                logDropdownList={serviceLogList.map((log) => log.serviceLogId === -1 ? log.logName + '(Not saved)' : log.logName)}
                                isLoading={isServiceLogLoading}
                                dropdownSelected={selectedServiceLogIndex === -1 || !serviceLogList || !serviceLogList[selectedServiceLogIndex] ? '' : serviceLogList[selectedServiceLogIndex].logName}
                                dataAvailableFrom={selectedServiceLogIndex === -1 || !serviceLogList || !serviceLogList[selectedServiceLogIndex] ? new Date() : serviceLogList[selectedServiceLogIndex].availableFrom}
                                lookBackPeriod={selectedServiceLogIndex === -1 || !serviceLogList || !serviceLogList[selectedServiceLogIndex] ? 7 : serviceLogList[selectedServiceLogIndex].lookBackPeriodInDays}
                                onServiceLogChange={onServiceLogChange}
                                onAddServiceLog={onAddServiceLog}
                                onDeleteServiceLog={onDeleteServiceLog}
                                onSaveServiceLog={onSaveServiceLog}
                                sectionStatus={serviceLogSectionStatus}
                                isCreatingNewLog={selectedServiceLogIndex !== -1 && serviceLogList && serviceLogList.length > 0 && serviceLogList[selectedServiceLogIndex]?.serviceLogId === -1}
                                isEditable={isEditable}
                                />
                        </React.Fragment>}
                        
                        {selectedServiceLogIndex !== -1 && serviceLogList[selectedServiceLogIndex]?.serviceLogId !== -1 && <React.Fragment>
                            <Row><h4 className="section-divider">STEP 3: CREATE LOG DEFINITIONS</h4></Row>
                            <ServiceLogDefinition 
                                isLoading={isServiceLogDefinitionLoading}
                                versionDropdownList={versionDropdownList}
                                selectedVersionValue={selectedVersionValue}
                                onSelectServiceLogDefinition={onSelectServiceLogDefinition}
                                onServiceLogDefinitionChange={onServiceLogDefinitionChange}
                                onCreateServiceLogDefinition={onCreateServiceLogDefinition}
                                onSaveServiceLogDefinition={onSaveServiceLogDefinition}
                                initDefinitionMessage={initDefinitionMessage}
                                definitionData={serviceLogDefinitionToUpdate}
                                dataSourceList={dataSourceList}
                                parserList={parserList}
                                sectionStatus={serviceLogDefinitionSectionStatus}
                                isEditable={isEditable}
                                basicColumns={basicColumns.current}
                                advancedColumns={advancedColumns.current}
                                />
                        </React.Fragment>}
                    </Container>
                </Container>}
            </div>
            
            {showDeleteServiceModal && <DeleteModal
                show={showDeleteServiceModal}
                title={serviceList[selectedServiceIndex].serviceId === -1 ? 'Are you sure to discard?' : 'Are you sure to delete?'}
                prompt={serviceList[selectedServiceIndex].serviceId === -1 ? 'The new service you are creating will be discarded.' : 'Service ' + serviceList[selectedServiceIndex]?.serviceName + ' and all of its logs will be deleted.'}
                onYes={onDeleteService}
                onNo={toggleDeleteServiceModal}
            />}

            {showDeprecateServiceModal && <DeleteModal 
                    show={showDeprecateServiceModal} 
                    onYes={onDeprecateService} 
                    onNo={toggleDeprecateServiceModal}
                    title={"Are you sure to deprecate?"}
                    prompt={`This action will deprecate service "${serviceList[selectedServiceIndex]?.serviceName}" and all of its logs.`}/>}
        </div>
    );
}

export default EvoServiceRegistration;
