import React, { Component } from 'react'
import uuid from 'react-uuid'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import DataService from '../../Dataservices/DataService'
import { SortOrderEnum, compareString, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'
import './PipelineStatusPage.css'
import { LoadingGif } from '../PublicComponents/HelperComponents';

class PipelineStatus extends Component {
    constructor(props) {
        super(props);

        this.statusObjectKeys = {
            None: 'None',
            Cube: 'Cube',
            DaysLate: 'DaysLate',
            Enabled: 'Enabled',
            LastDate: 'LastDate',
            MaestroApplicationName: 'MaestroApplicationName',
            MaestroJobName: 'MaestroJobName',
            MissingStreamTupleList: 'MissingStreamTupleList',
            ReasonList: 'ReasonList',
            Server: 'Server',
            SuggestionList: 'SuggestionList'
        }
        
        this.state = {
            isLoading: true,
            displayList: [],
            pipelineList: [],
            searchText: '',
            sortKey: this.statusObjectKeys.None,
            sortOrder: SortOrderEnum.None,
            error : null
        }
    }

    componentDidMount() {
        DataService.get('JobStatus/pipelinestatus').then(res => {
            let copy = res.data.slice(0)
            this.setState({
                isLoading: false,
                pipelineList: copy,
                displayList: res.data
            })
        }).catch(err => {
            console.error(err)
            this.setState({error : err.message});
        })
    }

    filterObject = (obj, searchText) => {
        if(!searchText) return true;

        let validSearchKeys = Object.values(this.statusObjectKeys)

        return Object.keys(obj).some(key => {
            if (validSearchKeys.indexOf(key) === -1) return false

            if (key === this.statusObjectKeys.ReasonList || key === this.statusObjectKeys.SuggestionList) {
                return obj[key].some(val => val.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            } else if (key === this.statusObjectKeys.MissingStreamTupleList) {
                return obj[key].some(val => val.m_Item2.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            } else {
                return String(obj[key]).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            }
        })
    }

    onChangeSearchText = e => {
        const { sortKey, sortOrder } = this.state
        let text = e.target.value
        let list = this.state.pipelineList.filter(status => this.filterObject(status, text))
        if (sortKey !== this.statusObjectKeys.None && sortOrder !== SortOrderEnum.None) {
            list.sort(this.compareObject(sortKey, sortOrder === SortOrderEnum.Ascending))
        }
        this.setState({
            searchText: text,
            displayList: list
        })
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.LastDate) {
                // compare dates  "12/9/2020"
                let date1 =Date.parse(obj1[key])
                let date2 = Date.parse(obj2[key])

                // May contain Invalid values. Make Invalid values always show first when sorting
                if (isNaN(date1) && isNaN(date2)) {
                    return compareString(obj1[key], obj2[key], isAscending)
                } else if (isNaN(date1)) {
                    return -1
                } else if(isNaN(date2)){
                    return 1
                }

                return isAscending ? date1 - date2 : date2 - date1
            } else if (key === this.statusObjectKeys.DaysLate) {
                // compare numbers
                let num1 = parseInt(obj1[key])
                let num2 = parseInt(obj2[key])
                
                // May contain Invalid values. Make Invalid values always show first when sorting
                if (isNaN(num1) && isNaN(num2)) {
                    return compareString(obj1[key], obj2[key], isAscending)
                } else if (isNaN(num1)) {
                    return -1
                } else if(isNaN(num2)){
                    return 1
                }

                return isAscending ? num1 - num2 : num2 - num1
            } else if (key === this.statusObjectKeys.ReasonList || key === this.statusObjectKeys.SuggestionList || key === this.statusObjectKeys.MissingStreamTupleList) {
                // compare these lists by their first value
                return compareString(obj1[key][0] || '', obj2[key][0] || '', isAscending)
            } else {
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    onSort = key => {
        let { sortKey, sortOrder, displayList, pipelineList, searchText } = this.state
        let nextOrder = getNextSortOrder(sortOrder, key !== sortKey)
        
        let sortedList = nextOrder === SortOrderEnum.None ? pipelineList.filter(status => this.filterObject(status, searchText)) : displayList.sort(this.compareObject(key, nextOrder === SortOrderEnum.Ascending))
        
        this.setState({
            sortKey: key,
            sortOrder: nextOrder,
            displayList: sortedList
        })
    }

    getDaysLateColor = (daysLateRaw) => {

        let daysLate = parseInt(daysLateRaw);

        if(daysLate > 3)
        {
            return "#d90000";
        }
        else if(daysLate > 1)
        {
            return "#a95719";
        }
        // This also catches NA or any other non number
        else
        {
            return "black";
        }
    }

    render() {
        let { sortKey, sortOrder } = this.state

        return (
            <div>
                {this.state.isLoading
                ? <LoadingGif/>
                : <div>
                    {this.state.error && <Alert variant='danger'>{this.state.error}</Alert>}
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group className="Label" controlId="pipelineStatusSearch">
                                    <h2>Current Pipeline Status</h2>                      
                                    <Form.Control type="text" placeholder="Search Pipeline Status" onChange={this.onChangeSearchText}/>
                                </Form.Group>
                            </Form>
                        </Col>
                        </Row>       
                        <div style={{ overflowX: 'auto' }} aria-live="polite" aria-atomic="true" aria-label={(this.state.displayList ? this.state.displayList.length + " results found" : "")}>
                        <Table striped bordered hover className="statusTable">
                            <thead>
                                <tr>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.Cube)} className="tableHeader" scope="col">
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.Cube)}
                                        Cube
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.LastDate)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.LastDate)}
                                        Last Date
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.DaysLate)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.DaysLate)}
                                        Days Late
                                    </th>
                                     <th onClick={this.onSort.bind(this, this.statusObjectKeys.ReasonList)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.ReasonList)}
                                        Reason
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.SuggestionList)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.SuggestionList)}
                                        Suggestion
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.MissingStreamTupleList)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.MissingStreamTupleList)}
                                        Missing Streams
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.CosmosOutputPath)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.CosmosOutputPath)}
                                        Cosmos Output Path
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.MaestroApplicationName)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.MaestroApplicationName)}
                                        SPI
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.MaestroJobName)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.MaestroJobName)}
                                        ADF Job Name
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.Server)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.Server)}
                                        Server
                                    </th>
                                    <th onClick={this.onSort.bind(this, this.statusObjectKeys.Enabled)} className="tableHeader" scope="col"> 
                                        {renderSortMark(sortKey, sortOrder, this.statusObjectKeys.Enabled)}
                                        Enabled
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.displayList.map(status => (
                                    <tr key={uuid()} className="pipelineStatus">
                                        <td className="noWordBreak">{status.Cube}</td>
                                        <td className="noWordBreak">{status.LastDate}</td>
                                        <td className="noWordBreak" style={{color: this.getDaysLateColor(status.DaysLate)}}>{status.DaysLate}</td>
                                        <td>
                                            {status.ReasonList.map(reason => (
                                                <p key={uuid()} className="noWordBreak">{reason}</p>
                                            ))}
                                        </td>
                                        <td>
                                            {status.SuggestionList.map(suggestion => (
                                                <p key={uuid()} className="wordBreak">{suggestion}</p>
                                            ))}
                                        </td>
                                        <td>
                                            {status.MissingStreamTupleList.map(stream => (
                                                <p key={uuid()} className="noWordBreak"><a className="accessibleLink" href={stream.m_Item1}>{stream.m_Item2}</a></p>
                                            ))}
                                        </td>
                                        <td className="noWordBreak"><a className="accessibleLink" href={status.CosmosOutputPath}>Cosmos Path</a></td>
                                        <td className="noWordBreak">{status.MaestroApplicationName}</td>
                                        <td className="noWordBreak">{status.MaestroJobName}</td>
                                        <td className="noWordBreak">{status.Server}</td>
                                        <td className="noWordBreak">{status.Enabled}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>}
            </div>
        )
    }
}

export default PipelineStatus;