import CosmicBaseDataService from "./CosmicBaseDataService";
import EvoBaseDataService from "./EvoBaseDataService";

const MonitoringController = 'PipelineMonitor';
const JobRunController = 'JobRun';
const RegistrationController = 'Registration';
const QueryController = 'Query';

const EvoDataService = {
    getAvailableEvoServices : () => {
        return CosmicBaseDataService.get('PerfScopeControl/GetAvailableServices');
    },

    pivots: (pivotsRequestObject) => {
        return CosmicBaseDataService.post('PerfScopeControl/Pivots', pivotsRequestObject);
    },

    nearestAvailableDate: (availableDateObject) => {
        return CosmicBaseDataService.post('PerfScopeControl/NearestAvailableDate', availableDateObject);
    },

    getMappings: (cosmicServiceName) => {
        return CosmicBaseDataService.get('PerfScopeControl/GetMappings?serviceName=' + cosmicServiceName);
    },

    latencyQuery: (requestObject) => {
        return CosmicBaseDataService.post('PerfScopeControl/LatencyQuery', requestObject);
    },

    getAllCommonMappings: () => {
        return CosmicBaseDataService.get('PerfScopeCheckpointMeasureMapping/GetAllCommonMappings');
    },

    editCommonMapping: (modifiedMapping) => {
        return CosmicBaseDataService.put('PerfScopeCheckpointMeasureMapping/EditCommonMapping', modifiedMapping);
    },

    promoteServiceLevelMappingsToCommon: (promotedMappings) => {
        return CosmicBaseDataService.post('PerfScopeCheckpointMeasureMapping/PromoteServiceLevelMappingsToCommon', promotedMappings);
    },

    getAllServicesWithMapping: () => {
        return CosmicBaseDataService.get('PerfScopeCheckpointMeasureMapping/GetAllServicesWithMapping');
    },

    doesServiceUseCommonMapping: (serviceName) => {
        return CosmicBaseDataService.get('PerfScopeCheckpointMeasureMapping/DoesServiceUseCommon?serviceName=' + serviceName);
    },

    modifyServiceUseCommon: (modifyUseCommonRequest) => {
        return CosmicBaseDataService.put('PerfScopeCheckpointMeasureMapping/ModifyServiceUseCommon', modifyUseCommonRequest);
    },

    getServiceLevelMappings: (serviceName) => {
        return CosmicBaseDataService.get('PerfScopeCheckpointMeasureMapping/GetAllServiceLevelMappings?serviceName=' + serviceName);
    },

    editServiceLevelMapping: (editServiceMappingRequest) => {
        return CosmicBaseDataService.put('PerfScopeCheckpointMeasureMapping/EditServiceLevelMapping', editServiceMappingRequest);
    },

    getEventLogOptions: () => {
        return CosmicBaseDataService.get('PerfScopeStatus/eventlogparam');
    },

    getRecentLogs: (urlParams) => {
        return CosmicBaseDataService.get('PerfScopeStatus/eventlog?' + urlParams);
    },
    
    getJobStatus: () => {
        return CosmicBaseDataService.get('PerfScopeStatus/jobstatus')
    },

    // Query
    getEvoServiceConfiguration: () => {
        return EvoBaseDataService.get(`${QueryController}/serviceConfiguration`);
    },

    evoPivots: (serviceId, logId, from, to) => {
        return EvoBaseDataService.get(`${QueryController}/serviceLogPivots/${from}/${to}/${serviceId}/${logId}`);
    },

    getEvoNearestAvailableDate: (serviceId, logId, from, to) => {
        return EvoBaseDataService.get(`${QueryController}/nearestAvailableDataDate/${from}/${to}/${serviceId}/${logId}`);
    },

    evoLatencyQuery: (request, cancellationToken) => {
        return EvoBaseDataService.post(`${QueryController}/serviceLogLatencyQuery`, request, cancellationToken, 'EvoLatencyQuery');
    },

    // New registration
    getCosmosViewSchema: viewPath => {
        return EvoBaseDataService.get(`${RegistrationController}/get/cosmosviewschema?viewPath=${viewPath}`)
    },

    getDataSources: () => {
        return EvoBaseDataService.get(`${RegistrationController}/get/datasource`);
    },

    getLogDefaultValues: () => {
        return EvoBaseDataService.get(`${RegistrationController}/get/defaultvalue`);
    },

    getParsers: () => {
        return EvoBaseDataService.get(`${RegistrationController}/get/parser`);
    },
    
    getDeprecatedServices : () => {
        return EvoBaseDataService.get(`${RegistrationController}/deprecated/services`);
    },

    getActiveServices : () => {
        return EvoBaseDataService.get(`${RegistrationController}/active/services`);
    },
    
    addOrUpdateService: (service, id) => {
        if (id !== undefined && id !== -1) {
            // update service
            return EvoBaseDataService.post(`${RegistrationController}/update/service/${id}`, service);
        } else {
            // add service
            return EvoBaseDataService.post(`${RegistrationController}/add/service`, service);
        }
    },

    deleteService: id => {
        return EvoBaseDataService.delete(`${RegistrationController}/delete/service/${id}`);
    },

    deprecateService: id => {
        return EvoBaseDataService.delete(`${RegistrationController}/deprecate/service/${id}`);
    },

    getActiveServiceLogsByServiceId: id => {
        return EvoBaseDataService.get(`${RegistrationController}/active/servicelogs/${id}`);
    },

    getDeprecatedServiceLogsByServiceId: id => {
        return EvoBaseDataService.get(`${RegistrationController}/deprecated/servicelogs/${id}`);
    },

    addOrUpdateServiceLog: (serviceLog, id) => {
        if (id !== undefined && id !== -1) {
            // update log
            return EvoBaseDataService.post(`${RegistrationController}/update/servicelog/${id}`, serviceLog);
        } else {
            // add log
            return EvoBaseDataService.post(`${RegistrationController}/add/servicelog`, serviceLog);
        }
    },

    deleteServiceLog: id => {
        return EvoBaseDataService.delete(`${RegistrationController}/delete/servicelog/${id}`);
    },

    getServiceLogDefinitionByLogId: id => {
        return EvoBaseDataService.get(`${RegistrationController}/servicelogdefinition/${id}`);
    },

    addServiceLogDefinition: data => {
        return EvoBaseDataService.post(`${RegistrationController}/add/servicelogdefinition`, data);
    },

    // Monitorings
    getServiceToLogMap: () => {
        return EvoBaseDataService.get(`${MonitoringController}/getservicetologmap`);
    },

    getMonitoredScheduledJobs: (from, to, serviceId, logId) => {
        return EvoBaseDataService.get(`${MonitoringController}/getscheduledjobs/${from}/${to}/${serviceId}/${logId}`);
    },

    getServiceLogJobRunsByScheduledJobId: (id) => {
        return EvoBaseDataService.get(`${MonitoringController}/getservicelogjobruns/${id}`);
    },

    getLogPropertyForMonitoring: (serviceLogJobRunId) => {
        return EvoBaseDataService.get(`${MonitoringController}/getlogproperty/${serviceLogJobRunId}`);
    },

    getDataAvailabilityDiagnostic: (stageRunId) => {
        return EvoBaseDataService.get(`${MonitoringController}/dataavailabilitydebug/${stageRunId}`);
    },

    getAggregationDiagnostic: (stageRunId) => {
        return EvoBaseDataService.get(`${MonitoringController}/aggregationdebug/${stageRunId}`);
    },

    getShardProcessingDiagnostic: (stageRunId) => {
        return EvoBaseDataService.get(`${MonitoringController}/shardprocessingdebug/${stageRunId}`);
    },

    getShardIngestionDiagnostic: (stageRunId) => {
        return EvoBaseDataService.get(`${MonitoringController}/shardingestiondebug/${stageRunId}`);
    },

    getJobStatistics: (jobGuid) => {
        return EvoBaseDataService.get(`${MonitoringController}/getjobstatistics/${jobGuid}`);
    },

    rerunJob: (scheduledJobId) =>{
        return EvoBaseDataService.post(`${JobRunController}/job/${scheduledJobId}`);
    },

    cancelJob: (scheduledJobId) => {
        return EvoBaseDataService.post(`${JobRunController}/cancel/${scheduledJobId}`);
    },

    // Ingestion Tasks
    getQuarantinedTasks: () => {
        return EvoBaseDataService.get('DataIngestionTasks/quarantinedTasks')
    },

    releaseTask: (taskId, queueName) => {
        // Append the parameters to the URL
        let fullUrl = `DataIngestionTasks/releaseTask?taskId=${taskId}&queueName=${queueName}`;
        return EvoBaseDataService.post(fullUrl);
    }
}

export default EvoDataService;
